import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Button } from 'semantic-ui-react'
import './modal.css'
import { useCallback } from 'react'
import { exportAction } from '../../../actions/clients.actions'

interface ExportModalProps {
	open: boolean
	onClose: () => void
}

function ExportModal({ open, onClose }: ExportModalProps) {
	const handleExport = useCallback((type: string) => {
		exportAction(type)
	}, [])

	return (
		<Dialog open={open} onClose={onClose} key="modal-config">
			<DialogTitle>Escolha o Modelo de Exportação</DialogTitle>
			<DialogContent>
				<Button
					variant="contained"
					className="excelButton"
					onClick={() => handleExport('xlsx')}
				>
					<i className="file excel icon" />
					Excel
				</Button>
				<Button
					variant="contained"
					className="csvButton"
					onClick={() => handleExport('csv')}
				>
					<i className="file alternate icon" />
					CSV
				</Button>
				<Button
					variant="contained"
					className="pdfButton"
					onClick={() => handleExport('pdf')}
				>
					<i className="file pdf icon" />
					PDF
				</Button>
			</DialogContent>
		</Dialog>
	)
}

export default ExportModal

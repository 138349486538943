import { Skeleton, SxProps, Theme } from '@mui/material'

interface FieldSkeletonProps {
	height?: number
	width?: number | string
	sx?: SxProps<Theme>
}

function FieldSkeleton({ height, width, sx, ...props }: FieldSkeletonProps) {
	return (
		<Skeleton
			animation="wave"
			sx={{
				height: height || 40,
				width,
				...sx
			}}
			{...props}
		/>
	)
}

export default FieldSkeleton

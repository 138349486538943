import { Grid, Paper } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import Chart from './chart'
import CardDashboard from './card'
import { monthToString } from '../../../utils'
import {
	getBillingTotalAction,
	getBillingTotalMonthAction,
	getCostMonthAction,
	getDailySalesWeekAction,
	getMediumTicketAction
} from '../../../actions/dashboard.actions'
import PendingPrizesGrid from './pending-prizes-grid'
import { PendingResults } from '../../../types/pending-results'
import { getPendingsSolicitationAction } from '../../../actions/roulette'

interface SalesDailyWeek {
	date: string
	day: string
	quantitySales: number
	valueCost: number
	valueBilling: number
}

function Metrics() {
	const [billingTotal, setBillingTotal] = useState<string | null>(null)
	const [billingMonth, setBillingMonth] = useState<string | null>(null)
	const [mediumTicket, setMediumTicket] = useState<string | null>(null)
	const [totalCost, setTotalCost] = useState<string | null>(null)
	const [salesDailyWeek, setSalesDailyWeek] = useState<SalesDailyWeek[] | null>(
		null
	)
	const [rowsPedingPrizes, setRowsPedingPrizes] = useState<
		PendingResults[] | null
	>(null)

	const fetchTotalBilling = useCallback(async () => {
		const billing = await getBillingTotalAction()
		setBillingTotal(billing.totalMoney)
	}, [setBillingTotal])

	const fetchBillingMonth = useCallback(async () => {
		const billing = await getBillingTotalMonthAction()
		setBillingMonth(billing.totalMoney)
	}, [setBillingMonth])

	const fetchMediumTicket = useCallback(async () => {
		const billing = await getMediumTicketAction()
		setMediumTicket(billing.mediumTicketMoney)
	}, [setMediumTicket])

	const fetchCostMonth = useCallback(async () => {
		const billing = await getCostMonthAction()
		setTotalCost(billing.totalMoney)
	}, [setTotalCost])

	const fetchSalesDailyWeek = useCallback(async () => {
		const sales = await getDailySalesWeekAction()
		setSalesDailyWeek(sales)
	}, [setSalesDailyWeek])

	const fetchPendingsPrizes = useCallback(async () => {
		const pendings = await getPendingsSolicitationAction()
		setRowsPedingPrizes(pendings)
	}, [setRowsPedingPrizes])

	useEffect(() => {
		fetchTotalBilling()
		fetchBillingMonth()
		fetchMediumTicket()
		fetchCostMonth()
		fetchSalesDailyWeek()
		fetchPendingsPrizes()
	}, [])

	return (
		<Grid container spacing={3}>
			<Grid
				item
				xs={12}
				md={6}
				lg={6}
				sx={{
					height: '100%'
				}}
			>
				<Grid container spacing={4}>
					<Grid item xs={6} sx={{ height: '24vh' }}>
						<CardDashboard
							title="Receita Geral"
							subtitle="Desde o início do sistema"
							value={billingTotal}
						/>
					</Grid>
					<Grid item xs={6} sx={{ height: '24vh' }}>
						<CardDashboard
							title="Receita Mês Atual"
							subtitle={monthToString(new Date())}
							value={billingMonth}
						/>
					</Grid>

					<Grid item xs={6} sx={{ height: '24vh' }}>
						<CardDashboard
							title="Valor em Prêmios"
							subtitle={monthToString(new Date())}
							value={totalCost}
						/>
					</Grid>
					<Grid item xs={6} sx={{ height: '24vh' }}>
						<CardDashboard
							title="Ticket Médio"
							subtitle="Média dos valores gastos por cliente"
							value={mediumTicket}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6} lg={6}>
				<Paper
					sx={{
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						height: '100%'
					}}
				>
					<Chart
						data={salesDailyWeek}
						keyX="day"
						keyY="valueBilling"
						title="Vendas Diárias"
						dataKeySeries="valueBilling"
						// labelX="Semana"
						labelY="Vendas (R$)"
					/>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
					<PendingPrizesGrid rows={rowsPedingPrizes} />
				</Paper>
			</Grid>
		</Grid>
	)
}

export default Metrics

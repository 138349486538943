import { Client } from '../types/clients'
import {
	parseDateFromPtBr,
	parseDateTimeFromPtBr,
	setYear
} from '../utils/parse-date'

export enum Sort {
	NAME_ASC = 'name_asc',
	NAME_DESC = 'name_desc',
	BIRTH_DATE_ASC = 'birth_date_asc',
	BIRTH_DATE_DESC = 'birth_date_desc',
	CREATED_AT_ASC = 'created_at_asc',
	CREATED_AT_DESC = 'created_at_desc'
}

export const handleSortCompare: Record<Sort, (a: Client, b: Client) => number> =
	{
		[Sort.NAME_ASC]: (a, b) =>
			a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
		[Sort.NAME_DESC]: (a, b) =>
			a.name.toUpperCase() < b.name.toUpperCase() ? 1 : -1,
		[Sort.BIRTH_DATE_ASC]: (a, b) =>
			setYear(parseDateFromPtBr(a.birthDate), new Date().getFullYear()) >
			setYear(parseDateFromPtBr(b.birthDate), new Date().getFullYear())
				? 1
				: -1,
		[Sort.BIRTH_DATE_DESC]: (a, b) =>
			setYear(parseDateFromPtBr(a.birthDate), new Date().getFullYear()) <
			setYear(parseDateFromPtBr(b.birthDate), new Date().getFullYear())
				? 1
				: -1,
		[Sort.CREATED_AT_ASC]: (a, b) =>
			parseDateTimeFromPtBr(a.createdAt) > parseDateTimeFromPtBr(b.createdAt)
				? 1
				: -1,
		[Sort.CREATED_AT_DESC]: (a, b) =>
			parseDateTimeFromPtBr(a.createdAt) < parseDateTimeFromPtBr(b.createdAt)
				? 1
				: -1
	}

import { Dropdown } from 'semantic-ui-react'
import { SyntheticEvent } from 'react'
import { Sort } from '../../../enums/sort-clients'

interface SelectSOrtProps {
	sort: Sort
	handleChange: (event: SyntheticEvent, data: any) => void
}

const options: {
	key: string
	icon: string
	text: string
	value: string
}[] = [
	{
		key: Sort.NAME_ASC,
		icon: 'sort alphabet ascending',
		text: 'Nome Crescente',
		value: Sort.NAME_ASC
	},
	{
		key: Sort.NAME_DESC,
		icon: 'sort alphabet descending',
		text: 'Nome Decrescente',
		value: Sort.NAME_DESC
	},
	{
		key: Sort.BIRTH_DATE_ASC,
		icon: 'sort numeric ascending',
		text: 'Data de Nascimento Crescente',
		value: Sort.BIRTH_DATE_ASC
	},
	{
		key: Sort.BIRTH_DATE_DESC,
		icon: 'sort numeric descending',
		text: 'Data de Nascimento Decrescente',
		value: Sort.BIRTH_DATE_DESC
	},
	{
		key: Sort.CREATED_AT_ASC,
		icon: 'sort numeric ascending',
		text: 'Criação Crescente',
		value: Sort.CREATED_AT_ASC
	},
	{
		key: Sort.CREATED_AT_DESC,
		icon: 'sort numeric descending',
		text: 'Criação Decrescente',
		value: Sort.CREATED_AT_DESC
	}
]

function SelectSort({ handleChange, sort }: SelectSOrtProps) {
	return (
		<Dropdown
			selection
			value={sort}
			onChange={handleChange}
			options={options}
		/>
	)
}

export default SelectSort

import { Grid, Paper } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import SolicitationsGrid from './solicitations-grid'
import { SolicitationRoulette } from '../../../types/solicitation'
import { getSolicitationRouletteAction } from '../../../actions/roulette'
import { SocketContext } from '../../../context/socket'
import { SocketModule } from '../../../enums/socket'

function Approbation() {
	const { message } = useContext(SocketContext)
	const [rows, setRows] = useState<SolicitationRoulette[] | null>(null)

	const getSolicitations = useCallback(async () => {
		const solicitations = await getSolicitationRouletteAction()
		setRows(solicitations)
	}, [setRows])

	const handleMessageSolicitation = useCallback(
		(message: SolicitationRoulette) => {
			const exists = rows?.find(row => row.id === message.id)
			if (exists) {
				return
			}
			if (rows === null) {
				setRows([message])
				return
			}
			setRows([message, ...rows])
		},
		[rows, setRows]
	)

	useEffect(() => {
		getSolicitations()
	}, [])

	useEffect(() => {
		if (!message) {
			return
		}
		const { data } = message
		if (!data) {
			return
		}
		if (data.includes(SocketModule.SOLICITATION_SPIN)) {
			const value = JSON.parse(data)
			handleMessageSolicitation(value.data)
		}
	}, [message])

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
					<SolicitationsGrid rows={rows} setRows={setRows} />
				</Paper>
			</Grid>
		</Grid>
	)
}
export default Approbation

import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const styleLink = document.createElement('link')
styleLink.rel = 'stylesheet'
styleLink.href =
	'https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css'
document.head.appendChild(styleLink)

const themeDark = createTheme({
	palette: {
		background: {
			default: '#030a14'
		}
	}
})

root.render(
	<React.StrictMode>
		<ThemeProvider theme={themeDark}>
			<CssBaseline />
			<App />
		</ThemeProvider>
	</React.StrictMode>
)

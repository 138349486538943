import {
	Button,
	Grid,
	IconButton,
	Link,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead
} from '@mui/material'
import { Search, TableRow } from 'semantic-ui-react'
import { useCallback, useEffect, useState } from 'react'
import { GetApp } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { Title } from '../../../components'
import Loading from '../../../components/loading'
import { Client } from '../../../types/clients'
import { statusToTag } from '../../../components/status-to-tag'
import { removeMask } from '../../../utils/remove-mask'
import './clients-grid.css'
import ExportModal from '../../dashboard/export-modal/modal'
import { useAuth } from '../../../hooks'
import WhatsAppWhiteIcon from '../../../components/icons/whatsapp-white'
import { Sort } from '../../../enums/sort-clients'
import SelectSort from './select-sort'
import SubTitle from '../../../components/sub-title'

interface ClientsGridProps {
	rows: Client[] | null
	handleSort: (sort: Sort) => void
	isAbsent: boolean
	isBirthday: boolean
	handleDoubleClickLine: (client: Client) => void
}

const PAGE_LENGTH = 10

function ClientsGrid({
	rows,
	handleSort,
	isAbsent,
	isBirthday,
	handleDoubleClickLine
}: ClientsGridProps) {
	const [renderRows, setRenderRows] = useState<Client[]>([])
	const [length, setLength] = useState(0)
	const [pageIndex, setPageIndex] = useState(0)
	const [openModalExport, setOpenModalExport] = useState(false)
	const [sort, setSort] = useState<Sort>(Sort.NAME_ASC)
	const { application } = useAuth()

	const pageRows = useCallback(() => {
		if (!rows) {
			return
		}
		setLength(rows.length)
		const newRows = rows.slice(
			pageIndex * PAGE_LENGTH,
			pageIndex * PAGE_LENGTH + PAGE_LENGTH
		)
		setRenderRows(newRows)
	}, [rows, pageIndex])

	useEffect(() => {
		const pages = Math.ceil(length / PAGE_LENGTH)
		if (pageIndex >= pages) {
			setPageIndex(pages)
		}
	}, [length])

	useEffect(() => {
		pageRows()
	}, [rows, pageIndex])

	useEffect(() => {
		handleSort(sort)
	}, [sort])

	const handleFilter = useCallback(
		(e: any) => {
			const { value } = e.target
			if (!rows) return
			if (!value) {
				setRenderRows(rows)
				pageRows()
				return
			}
			const filtered = rows.filter(
				row =>
					row.name.toLowerCase().includes(value.toLowerCase()) ||
					row.cellphoneNumber.includes(value)
			)
			setLength(filtered.length)
			setRenderRows(filtered)
		},
		[rows]
	)

	const handleChangeSort = useCallback(
		(_: any, sort: { value: Sort }) => {
			setSort(sort.value)
		},
		[setSort]
	)

	const renderButton = useCallback(
		(row: Client) => {
			if (isAbsent) {
				const message = (application?.absentClientsMessage || '').replace(
					/\{name\}/g,
					row.name
				)
				const link = `https://wa.me/${removeMask(row.cellphoneNumber)}?text=${encodeURI(message)}`
				return (
					<Link href={link} target="_blank">
						<Button
							variant="outlined"
							color="warning"
							sx={{
								backgroundColor: '#ff9f1c',
								color: '#fff',
								fontWeight: 'bold',
								borderColor: '#ff9f1c',
								':hover': {
									backgroundColor: '#2e7d32',
									color: '#fff',
									borderColor: '#2e7d32'
								}
							}}
						>
							<WhatsAppWhiteIcon height={20} style={{ paddingRight: 4 }} />
							Comunicar
						</Button>
					</Link>
				)
			}
			if (isBirthday) {
				const message = (application?.birthdayMessage || '').replace(
					/\{name\}/g,
					row.name
				)
				const link = `https://wa.me/${removeMask(row.cellphoneNumber)}?text=${encodeURI(message)}`
				return (
					<Link href={link} target="_blank">
						<Button
							variant="contained"
							color="success"
							sx={{
								backgroundColor: '#8963ba',
								color: '#fff',
								fontWeight: 'bold'
							}}
						>
							<WhatsAppWhiteIcon height={20} style={{ paddingRight: 4 }} />
							Parabenizar
						</Button>
					</Link>
				)
			}
			return (
				<IconButton onClick={() => handleDoubleClickLine(row)}>
					<SearchIcon sx={{ heigth: 20, color: '#000' }} />
				</IconButton>
			)
		},
		[isAbsent, isBirthday]
	)

	if (!rows) {
		return (
			<Grid
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%'
				}}
			>
				<Loading />
			</Grid>
		)
	}

	return (
		<>
			<ExportModal
				open={openModalExport}
				onClose={() => setOpenModalExport(false)}
			/>
			<Title>Clientes</Title>

			{isAbsent && (
				<SubTitle lowOpacity>
					Clientes que não possuem compras nos ultimos 60 dias
				</SubTitle>
			)}
			<Grid container spacing={4}>
				<Grid item xs={6}>
					<Search
						placeholder="Pesquisar"
						size="large"
						className="search"
						onSearchChange={handleFilter}
						showNoResults={false}
					/>
				</Grid>
				<Grid item xs={3}>
					<SelectSort sort={sort} handleChange={handleChangeSort} />
				</Grid>
				<Grid
					item
					xs={3}
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center'
					}}
				>
					<Button
						variant="contained"
						sx={{
							backgroundColor: '#94fb26',
							color: '#000',
							':hover': {
								backgroundColor: '#000',
								color: '#94fb26'
							}
						}}
						onClick={() => setOpenModalExport(true)}
					>
						<GetApp />
						Exportar
					</Button>
				</Grid>
			</Grid>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Nome</TableCell>
						<TableCell>Cadastro</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Telefone</TableCell>
						<TableCell>Aniversário</TableCell>
						<TableCell align="center">Ação</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{renderRows.map(row => (
						<TableRow
							key={row.id}
							onDoubleClick={() => handleDoubleClickLine(row)}
						>
							<TableCell>{row.name}</TableCell>
							<TableCell>{row.createdAt}</TableCell>
							<TableCell>{statusToTag[row.status]}</TableCell>
							<TableCell>{row.cellphoneNumber}</TableCell>
							<TableCell>{row.birthDate}</TableCell>
							<TableCell
								sx={{
									display: 'flex',
									justifyContent: 'space-evenly'
								}}
							>
								{renderButton(row)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Pagination
				count={Math.ceil(length / PAGE_LENGTH)}
				onChange={(_, value) => {
					setPageIndex(value - 1)
				}}
			/>
		</>
	)
}

export default ClientsGrid

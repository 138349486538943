import { Paper } from '@material-ui/core'
import { Grid } from '@mui/material'
import FieldSkeleton from '../../../components/field-loading'

function LoadingClientInfo() {
	return (
		<Grid>
			<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
				<Grid
					container
					spacing={4}
					sx={{
						display: 'flex',
						justifyContent: 'space-around'
					}}
				>
					<Grid
						item
						xs={4}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<FieldSkeleton
							height={120}
							sx={{ position: 'absolute', top: 75, width: 170, height: 150 }}
						/>
					</Grid>
					<Grid
						item
						xs={8}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<FieldSkeleton width="100%" />
					</Grid>
					<Grid item xs={4} />
					<Grid item xs={4}>
						<FieldSkeleton width="100%" />
					</Grid>
					<Grid item xs={4}>
						<FieldSkeleton width="100%" />
					</Grid>
					<Grid item xs={6}>
						<FieldSkeleton width="100%" />
					</Grid>
					<Grid item xs={6}>
						<FieldSkeleton width="100%" />
					</Grid>
					<Grid item xs={6}>
						<FieldSkeleton width="100%" />
					</Grid>
					<Grid item xs={6}>
						<FieldSkeleton width="100%" />
					</Grid>
					<Grid item xs={6}>
						<FieldSkeleton width="100%" />
					</Grid>
					<Grid item xs={6}>
						<FieldSkeleton width="100%" />
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	)
}

export default LoadingClientInfo

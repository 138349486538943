import {
	Button,
	Grid,
	Paper,
	TextField,
	Typography,
	styled
} from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'
import { Title } from '../../../components'
import SubTitle from '../../../components/sub-title'
import { RouletteOptions } from '../../../types/roulette'
import GridOptionsRoulette from '../../../components/config/grid-options'
import Loading from '../../../components/loading'
import FieldSkeleton from '../../../components/field-loading'
import {
	getOptionsRouletteAction,
	saveOptionsRouletteAction
} from '../../../actions/roulette-options'
import {
	downloadApplicationQrAction,
	getApplicationInfoAction
} from '../../../actions/application.actions'
import { useAuth } from '../../../hooks'

const defaultColors = ['#3E3E3E', '#DF3428']

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
})

function Settings() {
	const [rouletteMessage, setRouletteMessage] = useState('')
	const [birthdayMessage, setBirthdayMessage] = useState('')
	const [absentMessage, setAbsentMessage] = useState('')
	const [expireDays, setExpireDays] = useState(0)
	const [loadingSave, setLoadingSave] = useState(false)
	const [loadingLogo, setLoadingLogo] = useState(false)

	const [sumProbability, setSumProbability] = useState(0)

	const { uploadLogo, updateApplicationInfo } = useAuth()

	const [items, setItems] = useState<Partial<RouletteOptions>[]>([
		{
			name: '',
			color: defaultColors[0]
		}
	])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const sum = items.reduce((acc, item) => acc + (item.probability || 0), 0)
		setSumProbability(sum)
	}, [items])

	const handleSave = useCallback(async () => {
		try {
			setLoadingSave(true)
			await Promise.all([
				saveOptionsRouletteAction(items),
				updateApplicationInfo({
					absentClientsMessage: absentMessage,
					rouletteMessage,
					birthdayMessage,
					expireDays
				})
			])
			toast.success('Configurações salvas com sucesso')
			setLoadingSave(false)
		} catch (e) {
			toast.error('Erro ao salvar as configurações')
		}
	}, [items, rouletteMessage, birthdayMessage, absentMessage, expireDays])

	const loadingItems = useCallback(async () => {
		const options = await getOptionsRouletteAction()
		const {
			absentClientsMessage,
			rouletteMessage,
			birthdayMessage,
			expireDays
		} = await getApplicationInfoAction()
		setRouletteMessage(rouletteMessage || '')
		setBirthdayMessage(birthdayMessage || '')
		setAbsentMessage(absentClientsMessage || '')
		setExpireDays(expireDays || 0)
		setItems(options)
		setLoading(false)
	}, [setLoading])

	const handleUploadFile = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target?.files || !event.target?.files.length) {
				return
			}
			const file = event.target.files[0]
			setLoadingLogo(true)
			await uploadLogo(file)
			toast.success('Logo carregado com sucesso')
			setLoadingLogo(false)
		},
		[uploadLogo]
	)

	const handleDownloadQrCode = useCallback(async () => {
		await downloadApplicationQrAction()
	}, [])

	useEffect(() => {
		loadingItems()
	}, [])

	return (
		<Grid container spacing={1}>
			<Grid item xl={8} lg={8} md={12} xs={12}>
				<Paper
					sx={{
						p: 2,
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100%'
					}}
				>
					<Title>Configurações da Roleta</Title>
					<SubTitle lowOpacity>Escolha o número de opções da roleta</SubTitle>
					{loading ? (
						<Loading />
					) : (
						<GridOptionsRoulette
							items={items}
							setItems={setItems}
							sumProbability={sumProbability}
						/>
					)}
				</Paper>
			</Grid>
			<Grid item xl={4} lg={4} md={12} xs={12}>
				<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
					{loading ? (
						<Grid>
							<FieldSkeleton />
						</Grid>
					) : (
						<>
							<SubTitle fontSize={18}>
								Digite abaixo a frase que será mostrada na ROLETA
							</SubTitle>
							<TextField
								autoFocus
								required
								margin="dense"
								sx={{
									width: '100%'
								}}
								onChange={e => {
									setRouletteMessage(e.target.value)
								}}
								name="roulette_message"
								value={rouletteMessage}
								type="text"
							/>
						</>
					)}

					{loading ? (
						<Grid>
							<FieldSkeleton height={180} />
						</Grid>
					) : (
						<>
							<SubTitle fontSize={18}>
								Personalize a mensagem de aniversário
							</SubTitle>
							<TextField
								required
								margin="dense"
								sx={{
									width: '100%'
								}}
								onChange={e => {
									setBirthdayMessage(e.target.value)
								}}
								name="birthday_message"
								type="text"
								multiline
								value={birthdayMessage}
								rows={4}
								maxRows={7}
							/>
						</>
					)}

					{loading ? (
						<Grid>
							<FieldSkeleton height={180} />
						</Grid>
					) : (
						<>
							<SubTitle fontSize={18}>
								Personalize a frase para clientes ausentes
							</SubTitle>
							<TextField
								required
								margin="dense"
								sx={{
									width: '100%'
								}}
								onChange={e => {
									setAbsentMessage(e.target.value)
								}}
								name="absent_message"
								value={absentMessage}
								type="text"
								multiline
								rows={4}
								maxRows={7}
							/>
						</>
					)}

					{loading ? (
						<Grid>
							<FieldSkeleton height={180} />
						</Grid>
					) : (
						<>
							<SubTitle fontSize={18}>Dias para vencimento do Prêmio</SubTitle>
							<TextField
								required
								margin="dense"
								sx={{
									width: '100%'
								}}
								onChange={e => {
									setExpireDays(Number(e.target.value))
								}}
								name="expire_days"
								value={expireDays}
								type="number"
							/>
						</>
					)}
					<Grid container sx={{ paddingTop: 6, paddingBottom: 6 }}>
						<Grid
							item
							xs={6}
							sx={{
								textAlign: 'center',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<SubTitle fontSize={18}>QR Code</SubTitle>
							<Button
								variant="contained"
								startIcon={<DownloadIcon />}
								// eslint-disable-next-line no-alert
								onClick={handleDownloadQrCode}
							>
								Download
							</Button>
						</Grid>
						<Grid
							item
							xs={6}
							sx={{
								textAlign: 'center',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<SubTitle fontSize={18}>Carregar Logo</SubTitle>
							<Button
								component="label"
								role={undefined}
								variant="contained"
								tabIndex={-1}
								disabled={loadingLogo}
								startIcon={
									loadingLogo ? (
										<Loading
											size={20}
											sxProgress={{
												color: '#72bc16'
											}}
										/>
									) : (
										<UploadIcon />
									)
								}
							>
								Upload Logo
								<VisuallyHiddenInput
									onChange={handleUploadFile}
									accept=".jpg,.jpeg,.webp,.svg,.png"
									type="file"
									disabled={loadingLogo}
								/>
							</Button>
						</Grid>
					</Grid>
					<Grid
						sx={{
							display: 'flex',
							justifyContent: 'right',
							alignItems: 'right'
						}}
					>
						<Button
							variant="contained"
							sx={{
								backgroundColor: '#72bc16',
								color: '#000000',
								fontWeight: 'bold'
							}}
							disabled={loadingSave}
							onClick={handleSave}
						>
							{loadingSave ? (
								<Loading size={20} sxProgress={{ color: '#72bc16' }} />
							) : (
								'Salvar'
							)}
						</Button>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	)
}
export default Settings

import { ClientService } from '../services/client'
import { Client } from '../types/clients'
import { saveFileFromRequest } from '../utils/blob-functions'

export const exportAction = async (type: string): Promise<void> => {
	const service = new ClientService()
	const { data, headers } = await service.export(type)
	saveFileFromRequest(data, headers)
}

export const getClientsAction = async (): Promise<Client[]> => {
	const service = new ClientService()
	const { data } = await service.getClients()
	return data
}

export const getClientInfoAction = async (id: number) => {
	const service = new ClientService()
	const { data } = await service.getClientInfo(id)
	return data
}

import { Box, Button, Grid, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import FieldsModalConfig from './fields-roulette'
import { RouletteOptions } from '../../types/roulette'

const defaultColors = ['#3E3E3E', '#DF3428']

interface GridOptionsRouletteProps {
	items: Partial<RouletteOptions>[]
	sumProbability: number
	setItems: (items: Partial<RouletteOptions>[]) => void
}

function GridOptionsRoulette({
	items,
	sumProbability,
	setItems
}: GridOptionsRouletteProps) {
	const appendItem = useCallback(() => {
		const newItems = [...items]
		newItems.push({
			name: '',
			color: defaultColors[newItems.length % defaultColors.length]
		})
		setItems(newItems)
	}, [items, setItems])

	return (
		<Grid>
			<Box>
				{items.map((item, index) => (
					<FieldsModalConfig
						item={item}
						index={index}
						items={items}
						key={index}
						setItems={setItems}
					/>
				))}
			</Box>
			<Grid container>
				<Grid item xs={5} />
				<Grid
					item
					xs={2}
					sx={{
						display: 'flex',
						justifyContent: 'left',
						alignItems: 'left',
						paddingLeft: 2
					}}
				>
					<Typography
						variant="h6"
						color={sumProbability === 100 ? 'primary' : 'error'}
					>
						{sumProbability}/100
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					display: 'flex',
					justifyContent: 'left',
					alignItems: 'left',
					paddingRight: '0!important',
					paddingTop: 2
				}}
			>
				<Button variant="outlined" onClick={appendItem}>
					<AddIcon /> Adicionar
				</Button>
			</Grid>
		</Grid>
	)
}

export default GridOptionsRoulette

import { Typography } from '@mui/material'

function Copyright(props: any) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{'Copyright © '}
			{new Date().getFullYear()}. Todos direitos reservados.
		</Typography>
	)
}

export default Copyright

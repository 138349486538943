import { parse } from 'date-fns'

export const parseDateFromPtBr = (date: string) =>
	parse(date, 'dd/MM/yyyy', new Date())

export const parseDateTimeFromPtBr = (date: string) =>
	parse(date, 'dd/MM/yyyy, HH:mm:ss', new Date())

export const setYear = (date: Date, year: number) => {
	const newDate = new Date(date)
	newDate.setFullYear(year)
	return newDate
}

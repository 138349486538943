function FinancialIcon() {
	return (
		<img
			srcSet="/icons/icone_menu_financeiro.svg"
			src="/icons/icone_menu_financeiro.svg"
			alt="Financial"
			loading="lazy"
			style={{
				paddingLeft: 3
			}}
		/>
	)
}

export default FinancialIcon

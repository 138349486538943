import { HTTP } from '../providers'

export class DashboardService extends HTTP {
	async getBillingTotal() {
		return this.get({ endpoint: '/dashboard/total-billing' })
	}

	async getBillingTotalByMonth() {
		return this.get({ endpoint: '/dashboard/month-billing' })
	}

	async getMediumTicket() {
		return this.get({ endpoint: '/dashboard/medium-ticket' })
	}

	async getCostMonth() {
		return this.get({ endpoint: '/dashboard/month-cost' })
	}

	async getDailySalesWeek() {
		return this.get({ endpoint: '/dashboard/daily-sales-week' })
	}
}

import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import MuiDrawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Container from '@mui/material/Container'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import NotificationsIcon from '@mui/icons-material/Notifications'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { ReactNode, useCallback, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { ExitToAppSharp } from '@mui/icons-material'
import { Copyright } from '../../components'
import { mainListItems } from './list-items'
import ModalUser from './modal-user'
import { useAuth } from '../../hooks'

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
}

const drawerWidth: number = 240

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}))

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9)
			}
		})
	}
}))

const defaultTheme = createTheme()

interface PanelProps {
	children: ReactNode
	itemIndex: number
	title: string
}

function Panel({ children, itemIndex, title }: PanelProps) {
	const [open, setOpen] = useState(true)
	const [modalOpen, setModalOpen] = useState(false)
	const { logout } = useAuth()
	const toggleDrawer = () => {
		setOpen(!open)
	}

	const handleOpenModal = useCallback(() => {
		setModalOpen(true)
	}, [])

	return (
		<ThemeProvider theme={defaultTheme}>
			<ModalUser
				isOpen={modalOpen}
				onClose={() => {
					setModalOpen(false)
				}}
			/>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar position="absolute" open={open}>
					<Toolbar
						sx={{
							pr: '24px', // keep right padding when drawer closed,
							backgroundColor: '#61615f'
						}}
					>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={toggleDrawer}
							sx={{
								marginRight: '36px',
								...(open && { display: 'none' })
							}}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							sx={{ flexGrow: 1 }}
						>
							{title}
						</Typography>
						<IconButton color="inherit">
							<Badge badgeContent={0} color="secondary">
								<NotificationsIcon />
							</Badge>
						</IconButton>
						<IconButton color="inherit" onClick={handleOpenModal}>
							<Badge color="secondary">
								<AccountCircleIcon />
							</Badge>
						</IconButton>
					</Toolbar>
				</AppBar>
				<Drawer variant="permanent" open={open}>
					<Toolbar
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							px: [1]
						}}
					>
						<img
							srcSet="/logo.svg"
							src="/logo.svg"
							alt="Logo"
							loading="lazy"
							style={{
								height: '6vh'
							}}
						/>
						<IconButton onClick={toggleDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</Toolbar>
					<Divider />
					<List component="nav">
						{mainListItems(itemIndex)}
						<Divider sx={{ my: 1 }} />
					</List>
					<Grid
						sx={{
							height: '100%'
						}}
					/>
					<Divider />
					<Button variant="outlined" color="error" onClick={logout}>
						<ExitToAppSharp />
						<Typography sx={{ paddingLeft: 2 }} variant="h6">
							Sair
						</Typography>
					</Button>
				</Drawer>
				<Box
					component="main"
					sx={{
						backgroundColor: theme =>
							theme.palette.mode === 'light'
								? theme.palette.grey[100]
								: theme.palette.grey[900],
						flexGrow: 1,
						height: '100vh',
						paddingTop: '5vh',
						overflow: 'auto'
					}}
				>
					<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
						{children}
						{/* Chart */}
					</Container>
					<Toolbar />
					<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
						<Copyright sx={{ pt: 4 }} />
					</Container>
				</Box>
			</Box>
		</ThemeProvider>
	)
}

export default Panel

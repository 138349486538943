/* eslint-disable no-undef */
import { Dialog, DialogTitle } from '@mui/material'
import { useEffect } from 'react'

type DialogAwardProps = {
	awardName: string
	clientName: string
	open: boolean
	handleClose: () => void
}

function DialogAward({
	awardName,
	clientName,
	open,
	handleClose
}: DialogAwardProps) {
	let timeout: NodeJS.Timeout | undefined

	useEffect(() => {
		if (open)
			timeout = setTimeout(() => {
				handleClose()
			}, 8000)
	}, [open])

	return (
		<Dialog
			open={open}
			onClose={() => {
				if (timeout) clearTimeout(timeout)
				handleClose()
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Parabéns {clientName} você ganhou {awardName}
			</DialogTitle>
		</Dialog>
	)
}

export default DialogAward

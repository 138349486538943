import React, { useCallback, useEffect, useState } from 'react'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider, makeStyles } from '@mui/styles'
import {
	Box,
	Button,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useAuth } from '../../hooks'
import { Copyright } from '../../components'

const defaultTheme = createTheme()
const helperTextStyles = makeStyles({
	'&.MuiFormHelperText-root.Mui-error': {
		color: defaultTheme.palette.error.light
	}
})

type FormLoginProps = {
	showPassword: boolean
	errorEmail: boolean
	errorPassword: boolean
	handleClickShowPassword: () => void
	handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void
	handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
	helperText: Record<string, string>
}

function FormLogin({
	errorEmail,
	errorPassword,
	handleClickShowPassword,
	handleMouseDownPassword,
	handleSubmit,
	showPassword,
	helperText
}: FormLoginProps) {
	return (
		<Grid>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: 4
				}}
			>
				<img
					srcSet="/logo.svg"
					src="/logo.svg"
					alt="Logo"
					loading="lazy"
					style={{
						height: '7vh',
						marginTop: 20,
						marginBottom: 40
					}}
				/>
				<Grid
					sx={{
						alignItems: 'self-start',
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<Typography
						variant="h5"
						sx={{
							fontWeight: 'bold',
							textAlign: 'left',
							marginLeft: 4
						}}
					>
						Acessar
					</Typography>
					<Box
						component="form"
						onSubmit={handleSubmit}
						noValidate
						sx={{
							mt: 1,
							paddingLeft: 4,
							paddingRight: 4,
							zIndex: 3
						}}
					>
						<Grid item>
							<TextField
								id="outlined-adornment-password"
								sx={{
									width: '100%',
									marginBottom: 2
								}}
								placeholder="Email"
								name="email"
								label="Email"
								FormHelperTextProps={{ classes: helperText }}
								helperText={errorEmail ? 'Informe seu email' : ''}
								error={errorEmail}
							/>
							<TextField
								id="outlined-adornment-password"
								type={showPassword ? 'text' : 'password'}
								sx={{
									width: '100%'
								}}
								placeholder="Senha"
								name="password"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									)
								}}
								FormHelperTextProps={{ classes: helperText }}
								label="Senha"
								error={errorPassword}
								helperText={errorPassword ? 'Informe sua senha' : ''}
							/>
						</Grid>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{
								mt: 3,
								mb: 2,
								backgroundColor: '#000000',
								color: '#94fb26',
								height: '5vh',
								fontWeight: 'bold',
								'&:hover': {
									backgroundColor: '#94fb26',
									color: '#000000',
									fontWeight: 'bold'
								}
							}}
						>
							Entrar
						</Button>
						<Grid
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginTop: 2
							}}
						>
							<Typography
								sx={{
									fontSize: 15,
									marginLeft: 4
								}}
							>
								Ainda não tem uma conta?{' '}
								<a href="/register" style={{ color: '#94fb26' }}>
									Cadastrar
								</a>
							</Typography>
						</Grid>
					</Box>
				</Grid>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Box>
		</Grid>
	)
}

function Login() {
	const [showPassword, setShowPassword] = useState(false)
	const [errorEmail, setErrorEmail] = useState(false)
	const [errorPassword, setErrorPassword] = useState(false)
	const { search } = useLocation()
	const { login, isLogged, logout } = useAuth()
	const navigate = useNavigate()
	const helperText = helperTextStyles()

	const qs = new URLSearchParams(search)
	const url: string | null = qs.get('goto')

	const handleClickShowPassword = () => setShowPassword(show => !show)

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault()
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const formData = new FormData(event.currentTarget)
		const email = formData.get('email') as string
		const password = formData.get('password') as string
		if (!email) {
			setErrorEmail(true)
		}
		if (!password) {
			setErrorPassword(true)
		}
		if (!email || !password) {
			return
		}
		try {
			await login({
				email,
				password
			})
		} catch (error: any) {
			const message = error?.response?.data?.message
			if (message) {
				toast.error(message)
				return
			}
			toast.error('Erro ao realizar login')
		}
	}

	const isAuth = useCallback(() => {
		if (isLogged()) {
			if (url) {
				navigate(url)
				return
			}
			const defaultPage = localStorage.getItem('defaultPage')
			if (defaultPage) {
				navigate(defaultPage)
				return
			}
			navigate('/choose-screen')
			return
		}
		logout()
	}, [isLogged])

	useEffect(isAuth, [isAuth])

	const styles = {
		inputColor: {
			backgroundColor: '#cbcccd',
			borderRadius: 1,
			height: 40
		}
	}

	return (
		// <Paper style={styles.paper}>
		<ThemeProvider theme={defaultTheme}>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					backgroundImage: 'url(/bg_login.svg)',
					backgroundColor: 'white',
					backgroundPosition: 'left',
					backgroundSize: 'cover'
				}}
			>
				<Grid
					item
					xs={6}
					sx={{
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Grid
						item
						xs={12}
						sx={{
							height: '15vh'
						}}
					/>
					<Grid container>
						<Grid item xs={2} />
						<Grid
							item
							xs={8}
							sx={{
								backdropFilter: 'blur(10px)',
								borderRadius: 10,
								backgroundColor: 'rgba(14, 112, 158, 0.5)'
							}}
						>
							<FormLogin
								showPassword={showPassword}
								errorEmail={errorEmail}
								errorPassword={errorPassword}
								handleClickShowPassword={handleClickShowPassword}
								handleMouseDownPassword={handleMouseDownPassword}
								handleSubmit={handleSubmit}
								helperText={helperText}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={6}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					{/* </Grid> */}
				</Grid>
			</Grid>
		</ThemeProvider>
		// </Paper>
	)
}

export default Login

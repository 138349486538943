import { HTTP } from '../providers'

export class RouletteService extends HTTP {
	public async getHistoryRoulette() {
		return this.get({
			endpoint: '/roulette-results'
		})
	}

	public async getSolicitationRoulette() {
		return this.get({
			endpoint: '/roulette-results/solicitations'
		})
	}

	public async approveSolicitation(id: string) {
		return this.post({
			endpoint: `/roulette-solicitations/confirm-spin/${id}`,
			data: {}
		})
	}

	public async removeSolicitation(id: string) {
		return this.delete({
			endpoint: `/roulette-solicitations/cancel-spin/${id}`
		})
	}

	public async editSolicitationValue(id: string, value: number) {
		return this.put({
			endpoint: `/roulette-solicitations/${id}`,
			data: {
				value
			}
		})
	}

	public async getPendingsSolicitation() {
		return this.get({
			endpoint: '/roulette-results/pending'
		})
	}
}

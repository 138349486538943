import { HTTP } from '../providers'

export class UserService extends HTTP {
	async login(_params: { email: string; password: string }) {
		const login = await this.post({
			endpoint: '/login',
			data: _params
		})
		return login
	}

	async logout() {
		return this.get({
			endpoint: '/logout'
		})
	}

	async register(_params: {
		email: string
		password: string
		name: string
		code: string
	}) {
		const register = await this.post({
			endpoint: '/signup',
			data: _params
		})
		return register
	}
}

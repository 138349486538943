import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField
} from '@mui/material'
import { SolicitationRoulette } from '../../../types/solicitation'
import MoneyField from '../../../components/money-field'

interface ModalChangeValueProps {
	row: SolicitationRoulette | null
	handleClose: () => void
	handleSaveEdit: (row: SolicitationRoulette) => void
}

function ModalChangeValue({
	row,
	handleClose,
	handleSaveEdit
}: ModalChangeValueProps) {
	return (
		<Dialog
			open={Boolean(row)}
			onClose={handleClose}
			fullWidth
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Alterar Valor da Compra</DialogTitle>
			<DialogContent>
				<Grid container sx={{ padding: 3 }}>
					<MoneyField
						label="Valor da Compra"
						fullWidth
						value={row ? row.value.replace(/\./, '').replace(',', '.') : ''}
						onChange={e => {
							if (row) {
								row.value = e.target.value
							}
						}}
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancelar</Button>
				<Button
					onClick={() => {
						if (row) handleSaveEdit(row)
					}}
				>
					Salvar
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ModalChangeValue

import { CircularProgress, Grid, SxProps, Theme } from '@mui/material'

interface LoadingProps {
	sx?: SxProps<Theme>
	sxProgress?: SxProps<Theme>
	size?: number
}

function Loading({ sx, sxProgress, size }: LoadingProps) {
	return (
		<Grid
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				...sx
			}}
		>
			<CircularProgress
				size={size}
				sx={{
					color: '#94fb26',
					...sxProgress
				}}
			/>
		</Grid>
	)
}

export default Loading

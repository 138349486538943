import { CloseSharp, Done } from '@mui/icons-material'
import {
	Button,
	Card,
	CardActionArea,
	CardHeader,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography
} from '@mui/material'

type Features = {
	title: string
	status: boolean
}

interface CardPlanProps {
	title: string
	price: string
	features: Features[]
	color: string
	image: string
	imageAlt: string
	handleClick: () => void
}

function CardPlan({
	features,
	handleClick,
	price,
	title,
	color,
	image,
	imageAlt
}: CardPlanProps) {
	return (
		<Card
			raised
			sx={{
				flexGrow: 1,
				outline: '1px solid',
				outlineColor: color,
				backgroundColor: 'background.default',
				borderRadius: 5
			}}
		>
			<CardHeader
				title={title}
				sx={{
					backgroundColor: color,
					color: 'white',
					textAlign: 'center',
					borderRadius: '5px'
				}}
			/>
			<CardActionArea
				onClick={() => {}}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<Typography
					variant="h4"
					sx={{
						padding: 4,
						color: 'rgba(0, 0, 0, 0.57)',
						fontWeight: 'bold',
						fontSize: 38
					}}
				>
					{price}
				</Typography>
				<Divider variant="middle" flexItem orientation="horizontal" />
				<Grid
					sx={{
						width: '100%',
						paddingLeft: 2,
						paddingTop: 2,
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<img src={image} alt={imageAlt} height={200} />
				</Grid>
				<Grid
					sx={{
						width: '100%',
						paddingLeft: 4
					}}
				>
					<List>
						{features.map((feature, index) => (
							<ListItem disablePadding key={index}>
								{feature.status ? (
									<Done sx={{ color: '#99f32b' }} />
								) : (
									<CloseSharp sx={{ color: '#fc8c6a' }} />
								)}
								<ListItemText sx={{ paddingLeft: 2 }} primary={feature.title} />
							</ListItem>
						))}
					</List>
				</Grid>
				<Grid
					sx={{
						paddingBottom: 4,
						paddingTop: 4,
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Button
						variant="contained"
						size="large"
						sx={{
							backgroundColor: color,
							width: '80%'
						}}
						onClick={handleClick}
					>
						ASSINAR AGORA
					</Button>
				</Grid>
			</CardActionArea>
		</Card>
	)
}

export default CardPlan

import React from 'react'

interface WhatsAppIconProps {
	height?: number
	style?: React.CSSProperties
}

function WhatsAppWhiteIcon({ height, style }: WhatsAppIconProps) {
	return (
		<img
			srcSet="/icons/icone_whatsapp_branco.svg"
			src="/icons/icone_whatsapp_branco.svg"
			alt="WhatsApp"
			loading="lazy"
			style={{
				paddingLeft: 3,
				height,
				color: '#fff',
				...style
			}}
		/>
	)
}

export default WhatsAppWhiteIcon

import * as React from 'react'
import Typography from '@mui/material/Typography'

interface TitleProps {
	children?: React.ReactNode
	lowOpacity?: boolean
	fontSize?: number
}

export default function SubTitle({
	children,
	lowOpacity,
	fontSize
}: TitleProps) {
	return (
		<Typography
			component="header"
			variant="subtitle1"
			sx={{
				fontWeight: 'bold',
				color: lowOpacity ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 1)',
				fontSize: fontSize || 14
			}}
		>
			{children}
		</Typography>
	)
}

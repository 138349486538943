import { HTTP } from '../providers'

export class ClientService extends HTTP {
	async export(type: string) {
		return this.get({
			endpoint: `/clients/export`,
			params: { type },
			responseType: 'blob'
		})
	}

	async getClients() {
		return this.get({ endpoint: '/clients' })
	}

	async getClientInfo(id: number) {
		return this.get({ endpoint: `/clients/${id}` })
	}
}

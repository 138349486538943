import { DashboardService } from '../services'

type Billing = {
	totalMoney: string
	total: number
}

type MediumTicket = {
	mediumTicketMoney: string
	mediumTicket: number
}

type DailySalesWeek = {
	date: string
	day: string
	quantitySales: number
	valueCost: number
	valueBilling: number
}

export const getBillingTotalAction = async (): Promise<Billing> => {
	const service = new DashboardService()
	const response = await service.getBillingTotal()
	return response.data as Billing
}

export const getBillingTotalMonthAction = async (): Promise<Billing> => {
	const service = new DashboardService()
	const response = await service.getBillingTotalByMonth()
	return response.data as Billing
}

export const getMediumTicketAction = async (): Promise<MediumTicket> => {
	const service = new DashboardService()
	const response = await service.getMediumTicket()
	return response.data as MediumTicket
}

export const getCostMonthAction = async (): Promise<Billing> => {
	const service = new DashboardService()
	const response = await service.getCostMonth()
	return response.data as Billing
}

export const getDailySalesWeekAction = async (): Promise<DailySalesWeek[]> => {
	const service = new DashboardService()
	const response = await service.getDailySalesWeek()
	return response.data.sales as DailySalesWeek[]
}

import { ClientStatus } from '../enums/client'

const statusMap: Record<ClientStatus, string> = {
	[ClientStatus.OURO]: 'Ouro',
	[ClientStatus.AUSENTE]: 'Ausente',
	[ClientStatus.ESPORADICO]: 'Esporádico',
	[ClientStatus.FREQUENTE]: 'Frequente',
	[ClientStatus.PRIMEIRA_COMPRA]: 'Primeira Compra'
}

export const statusToString = (status: ClientStatus) => statusMap[status]

import { Badge, Grid, ToggleButton } from '@mui/material'
import {
	Bookmark,
	Celebration,
	CoPresent,
	NotificationsPaused,
	People,
	PersonAdd,
	WorkspacePremium
} from '@mui/icons-material'
import { useCallback, useEffect } from 'react'
import { ClientStatus } from '../../../enums/client'
import { Client } from '../../../types/clients'
import { parseDateFromPtBr } from '../../../utils/parse-date'
import { dateSameMonth } from '../../../utils/date-compare'

interface ClientFilterStatusProps {
	selectedIndex: number
	setSelectedIndex: (index: number) => void
	rows: Client[] | null
	setFilterRows: (rows: Client[] | null) => void
	setAbsent: (absent: boolean) => void
	setBirthday: (birthday: boolean) => void
}

interface ClientStatusFilter {
	status?: ClientStatus
	birthdate?: boolean
	all?: boolean
	icon: JSX.Element
	label: string
}

const statusFilter: ClientStatusFilter[] = [
	{
		all: true,
		icon: <People fontSize="small" sx={{ mr: 1 }} />,
		label: 'Todos'
	},
	{
		status: ClientStatus.OURO,
		icon: <WorkspacePremium fontSize="small" sx={{ mr: 1 }} />,
		label: 'Ouro'
	},
	{
		status: ClientStatus.AUSENTE,
		icon: <NotificationsPaused fontSize="small" sx={{ mr: 1 }} />,
		label: 'Ausente'
	},
	{
		status: ClientStatus.ESPORADICO,
		icon: <CoPresent fontSize="small" sx={{ mr: 1 }} />,
		label: 'Esporádico'
	},
	{
		status: ClientStatus.FREQUENTE,
		icon: <Bookmark fontSize="small" sx={{ mr: 1 }} />,
		label: 'Frequente'
	},
	{
		status: ClientStatus.PRIMEIRA_COMPRA,
		icon: <PersonAdd fontSize="small" sx={{ mr: 1 }} />,
		label: 'Primeira compra'
	},
	{
		birthdate: true,
		icon: <Celebration fontSize="small" sx={{ mr: 1 }} />,
		label: 'Aniversário'
	}
]

function ClientFilterStatus({
	selectedIndex,
	rows,
	setFilterRows,
	setAbsent,
	setBirthday,
	setSelectedIndex
}: ClientFilterStatusProps) {
	useEffect(() => {
		if (statusFilter[selectedIndex].birthdate) {
			setBirthday(true)
			setAbsent(false)
			return
		}
		setBirthday(false)
		if (statusFilter[selectedIndex].status === ClientStatus.AUSENTE) {
			setAbsent(true)
			return
		}
		setAbsent(false)
	}, [selectedIndex])

	const handleFilter = useCallback(
		(filter: ClientStatusFilter): Client[] | null => {
			if (!rows) return null
			return rows.filter(row => {
				if (filter.all) return true
				if (row.status === filter.status) return true
				if (filter.birthdate) {
					const date = parseDateFromPtBr(row.birthDate)
					if (dateSameMonth(date, new Date())) return true
				}
				return false
			})
		},
		[rows]
	)

	const handleChangeFilter = useCallback(
		(index: number) => {
			setSelectedIndex(index)
			setFilterRows(handleFilter(statusFilter[index]))
		},
		[setSelectedIndex, setFilterRows, handleFilter]
	)

	return (
		<Grid
			container
			spacing={2}
			padding={2}
			columns={7}
			sx={{
				display: 'flex',
				justifyContent: 'space-between'
			}}
		>
			{statusFilter.map((filter, index) => (
				<Grid item xs={1} key={index}>
					<ToggleButton
						value=""
						selected={selectedIndex === index}
						sx={{
							width: '100%',
							height: '100%',
							display: 'grid',
							fontSize: '0.7rem'
						}}
						onChange={() => handleChangeFilter(index)}
					>
						<Grid>{filter.icon}</Grid>
						<Grid>
							{filter.label}
							<Badge
								sx={{
									position: 'absolute',
									top: 50,
									right: 15
								}}
								badgeContent={handleFilter(filter)?.length || 0}
								color="success"
								max={9999}
							/>
						</Grid>
					</ToggleButton>
				</Grid>
			))}
		</Grid>
	)
}

export default ClientFilterStatus

import { Grid } from '@mui/material'
import { ClientStatus } from '../enums/client'

export const statusToTag: Record<ClientStatus, JSX.Element> = {
	[ClientStatus.OURO]: <Grid>Ouro</Grid>,
	[ClientStatus.AUSENTE]: <Grid>Ausente</Grid>,
	[ClientStatus.ESPORADICO]: <Grid>Esporádico</Grid>,
	[ClientStatus.FREQUENTE]: <Grid>Frequente</Grid>,
	[ClientStatus.PRIMEIRA_COMPRA]: <Grid>Primeira Compra</Grid>
}

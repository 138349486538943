import { ApplicationService } from '../services/application'
import { Application, SaveApplicationInfo } from '../types/application'
import { saveFileFromRequest } from '../utils/blob-functions'

type UploadResponse = { fileName: string }

export const uploadLogoAction = async (logo: File): Promise<UploadResponse> => {
	const service = new ApplicationService()
	const response = await service.uploadLogo(logo)
	return response.data as UploadResponse
}

export const existsApplicationCodeAction = async (
	code: string
): Promise<boolean> => {
	const service = new ApplicationService()
	const response = await service.existsApplicationCode(code)
	return response.data as boolean
}

export const getApplicationInfoAction = async (): Promise<Application> => {
	const service = new ApplicationService()
	const response = await service.getApplicationInfo()
	return response.data as Application
}

export const saveApplicationInfoAction = async (
	application: SaveApplicationInfo
): Promise<Application> => {
	const service = new ApplicationService()
	const response = await service.saveInfo(application)
	return response.data as Application
}

export const downloadApplicationQrAction = async (): Promise<void> => {
	const service = new ApplicationService()
	const { data, headers } = await service.downloadQr()
	saveFileFromRequest(data, headers)
}

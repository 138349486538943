import {
	Box,
	Card,
	CardContent,
	Divider,
	Grid,
	Typography
} from '@mui/material'
import { formatDateHourPtBr, formatPhone } from '../utils'

type RouletteCardProps = {
	nameClient: string
	phoneClient: string
	awardName: string
	createdAt: Date
}

function RouletteCard(item: RouletteCardProps) {
	const { createdAt, nameClient, phoneClient, awardName } = item
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				p: 2
			}}
		>
			<Card variant="outlined" sx={{ width: '100%' }}>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Box sx={{ p: 2 }}>
								<Typography
									sx={{ fontSize: 14 }}
									variant="h3"
									color="text.secondary"
									gutterBottom
								>
									{nameClient}
								</Typography>
								<Typography sx={{ fontSize: 14 }} variant="h4">
									{formatPhone(phoneClient)}
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							xs={6}
							sx={{
								display: 'flex',
								justifyContent: 'end'
							}}
						>
							<Box sx={{ p: 2 }}>
								<Typography
									sx={{ fontSize: 14 }}
									variant="h3"
									color="text.secondary"
									gutterBottom
								>
									Prêmio
								</Typography>
								<Typography sx={{ fontSize: 14 }} variant="h4">
									{awardName}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Divider />
					<Box sx={{ p: 2 }}>
						<Typography sx={{ fontSize: 14 }} variant="h4">
							{formatDateHourPtBr(createdAt)}
						</Typography>
					</Box>
				</CardContent>
			</Card>
		</Box>
	)
}

export default RouletteCard

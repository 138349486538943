function ApprobationIcon() {
	return (
		<img
			srcSet="/icons/icone_menu_aprovacao.svg"
			src="/icons/icone_menu_aprovacao.svg"
			alt="Approbation"
			loading="lazy"
			style={{
				paddingLeft: 3
			}}
		/>
	)
}

export default ApprobationIcon

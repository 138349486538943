import { Navigate, Outlet } from 'react-router'
import { useAuth } from '../hooks'

type RouterProps = {
	Component: any
	path?: string
	exact?: boolean
}

function PrivateRoutes({ Component, path, ...rest }: RouterProps) {
	const { isLogged } = useAuth()

	if (isLogged()) {
		if (path === '/login' || path === '/') {
			return <Navigate to="/dashboard" />
		}
		return Component
	}
	return <Navigate to="/login" />
}

export default PrivateRoutes

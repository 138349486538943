import { UserService } from '../services'
import { Application } from '../types/application'

type LoginResponse = {
	token: string
	imageApplication: string
	application: Application
}

export const loginAction = async (
	email: string,
	password: string
): Promise<LoginResponse> => {
	const service = new UserService()
	const response = await service.login({ email, password })
	return response.data as LoginResponse
}

export const registerAction = async (
	email: string,
	password: string,
	name: string,
	code: string
): Promise<LoginResponse> => {
	const service = new UserService()
	const response = await service.register({ email, password, name, code })
	return response.data as LoginResponse
}

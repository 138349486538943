import { Box, Grid, Pagination, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { RouletteCard } from '../../../components'
import { Spin } from '../../../types/spin'

interface HistoryGridProps {
	history: Spin[]
}
const PAGE_LENGTH = 4

function HistoryGrid({ history }: HistoryGridProps) {
	const [pageIndex, setPageIndex] = useState(0)
	const [historyOnPage, setHistoryOnPage] = useState<Spin[]>([])

	useEffect(() => {
		const newHistory = history.slice(
			pageIndex * PAGE_LENGTH,
			pageIndex * PAGE_LENGTH + PAGE_LENGTH
		)
		setHistoryOnPage(newHistory)
	}, [history, pageIndex, setHistoryOnPage])

	return (
		<Grid
			item
			xs={12}
			sx={{
				p: 2
			}}
			height="100%"
		>
			<Grid
				height="100%"
				border={{
					borderColor: 'primary.main',
					borderStyle: 'solid',
					borderWidth: 1
				}}
				sx={{
					borderRadius: 5,
					backgroundColor: '#f7f5ed'
				}}
			>
				<Typography
					variant="h2"
					sx={{
						textAlign: 'center'
					}}
				>
					Histórico
				</Typography>
				<Grid>
					{historyOnPage.map((item, index) => (
						<RouletteCard
							createdAt={item.createdAt}
							nameClient={item.name}
							phoneClient={item.cellphoneNumber}
							awardName={item.selectedOption.name}
							key={index}
						/>
					))}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							p: 2
						}}
					>
						<Pagination
							count={Math.ceil(history.length / PAGE_LENGTH)}
							onChange={(_, value) => {
								setPageIndex(value - 1)
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default HistoryGrid

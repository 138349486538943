import React, { useCallback, useEffect, useState } from 'react'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider, makeStyles } from '@mui/styles'
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
	circularProgressClasses
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Info, Visibility, VisibilityOff } from '@mui/icons-material'
import { useAuth } from '../../hooks'
import { Copyright } from '../../components'
import { existsApplicationCodeAction } from '../../actions/application.actions'

const defaultTheme = createTheme()
const helperTextStyles = makeStyles({
	'&.MuiFormHelperText-root.Mui-error': {
		color: defaultTheme.palette.error.light
	}
})

function Register() {
	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setConfirmShowPassword] = useState(false)
	const [errorEmail, setErrorEmail] = useState(false)
	const [errorName, setErrorName] = useState(false)
	const [errorCode, setErrorCode] = useState('')
	const [errorPassword, setErrorPassword] = useState(false)
	const [searchCode, setSearchCode] = useState('')
	const [codeSuccess, setCodeSuccess] = useState(false)
	const [codeLoading, setCodeLoading] = useState(false)
	const [errorConfirmPassword, setErrorConfirmPassword] = useState('')
	const { search } = useLocation()
	const { register, isLogged, logout } = useAuth()
	const navigate = useNavigate()
	const helperText = helperTextStyles()

	const qs = new URLSearchParams(search)
	const url: string | null = qs.get('goto')

	const handleClickShowPassword = () => setShowPassword(show => !show)

	const handleClickShowConfirmPassword = () =>
		setConfirmShowPassword(show => !show)

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault()
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const formData = new FormData(event.currentTarget)
		const email = formData.get('email') as string
		const password = formData.get('password') as string
		const confirmPassword = formData.get('confirmPassword') as string
		const code = formData.get('code') as string
		const name = formData.get('name') as string
		if (!code) {
			setErrorCode('Informe o código da Empresa')
		}
		if (!name) {
			setErrorName(true)
		}
		if (!email) {
			setErrorEmail(true)
		}
		if (!password) {
			setErrorPassword(true)
		}
		if (!confirmPassword) {
			setErrorConfirmPassword('Confirmar senha é obrigatório')
		}
		if (password !== confirmPassword) {
			setErrorConfirmPassword('As senhas não conferem')
			return
		}
		if (!email || !password || !confirmPassword || !name || !code) {
			return
		}
		try {
			await register({
				email,
				password,
				name,
				code
			})
		} catch (error: any) {
			const message = error?.response?.data?.message
			if (message) {
				toast.error(message)
				return
			}
			toast.error('Erro ao realizar login')
		}
	}

	const isAuth = useCallback(() => {
		if (isLogged()) {
			if (url) {
				navigate(url)
				return
			}
			navigate('/dashboard')
			return
		}
		logout()
	}, [isLogged])

	useEffect(() => {
		const delayDebounceFn = setTimeout(async () => {
			if (!searchCode) return
			try {
				setCodeLoading(true)
				await existsApplicationCodeAction(searchCode)
				setCodeSuccess(true)
				setErrorCode('')
			} catch (e: any) {
				if (e?.status === 409) {
					setErrorCode('Código já cadastrado')
					return
				}
				setErrorCode('Erro ao consultar código')
			} finally {
				setCodeLoading(false)
			}
		}, 600)

		return () => clearTimeout(delayDebounceFn)
	}, [searchCode])

	useEffect(isAuth, [isAuth])

	return (
		// <Paper style={styles.paper}>
		<ThemeProvider theme={defaultTheme}>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					backgroundImage: 'url(/bg_login.svg)',
					backgroundColor: 'white',
					backgroundPosition: 'left',
					backgroundSize: 'cover'
				}}
			>
				<Grid
					item
					xs={6}
					sx={{
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Grid
						item
						xs={12}
						sx={{
							height: '15vh'
						}}
					/>
					<Grid container>
						<Grid item xs={2} />
						<Grid
							item
							xs={8}
							sx={{
								backdropFilter: 'blur(10px)',
								borderRadius: 10,
								backgroundColor: 'rgba(14, 112, 158, 0.5)'
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									padding: 4
								}}
							>
								<img
									srcSet="/logo.svg"
									src="/logo.svg"
									alt="Logo"
									loading="lazy"
									style={{
										height: '7vh'
									}}
								/>
								<Grid
									sx={{
										alignItems: 'self-start',
										display: 'flex',
										flexDirection: 'column'
									}}
								>
									<Typography
										variant="h5"
										sx={{
											fontWeight: 'bold',
											textAlign: 'left',
											marginLeft: 4
										}}
									>
										Cadastrar
									</Typography>
									<Box
										component="form"
										onSubmit={handleSubmit}
										noValidate
										sx={{
											mt: 1,
											paddingLeft: 4,
											paddingRight: 4,
											zIndex: 3
										}}
									>
										<Grid item>
											<TextField
												id="outlined-name"
												sx={{
													width: '100%',
													marginBottom: 2
												}}
												placeholder="Nome da Empresa"
												name="name"
												label="Nome da Empresa"
												FormHelperTextProps={{ classes: helperText }}
												helperText={
													errorName ? 'Informe o nome da Empresa' : ''
												}
												error={errorName}
											/>
											<TextField
												id="outlined-code"
												sx={{
													width: '100%',
													marginBottom: 2
												}}
												placeholder="Código da Empresa"
												name="code"
												label="Código da Empresa"
												FormHelperTextProps={{ classes: helperText }}
												helperText={errorCode}
												error={Boolean(errorCode)}
												color={codeSuccess ? 'success' : undefined}
												onChange={e => setSearchCode(e.target.value)}
												InputProps={
													codeLoading
														? {
																endAdornment: (
																	<CircularProgress
																		variant="indeterminate"
																		disableShrink
																		color="inherit"
																		sx={{
																			animationDuration: '750ms',
																			[`& .${circularProgressClasses.circle}`]:
																				{
																					strokeLinecap: 'round'
																				}
																		}}
																		thickness={4}
																	/>
																)
															}
														: {
																endAdornment: (
																	<InputAdornment position="end">
																		<Tooltip title="Por favor, forneça um código de segurança específico, para seu cliente enviar e participar da roleta">
																			<Info />
																		</Tooltip>
																	</InputAdornment>
																)
															}
												}
											/>
											<TextField
												id="outlined-adornment-password"
												sx={{
													width: '100%',
													marginBottom: 2
												}}
												placeholder="Email"
												name="email"
												label="Email"
												FormHelperTextProps={{ classes: helperText }}
												helperText={errorEmail ? 'Informe seu email' : ''}
												error={errorEmail}
											/>
											<TextField
												id="outlined-adornment-password"
												type={showPassword ? 'text' : 'password'}
												sx={{
													width: '100%',
													marginBottom: 2
												}}
												placeholder="Senha"
												name="password"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? (
																	<VisibilityOff />
																) : (
																	<Visibility />
																)}
															</IconButton>
														</InputAdornment>
													)
												}}
												FormHelperTextProps={{ classes: helperText }}
												label="Senha"
												error={errorPassword}
												helperText={errorPassword ? 'Informe sua senha' : ''}
											/>
											<TextField
												id="outlined-adornment-confirm-password"
												type={showConfirmPassword ? 'text' : 'password'}
												sx={{
													width: '100%'
												}}
												placeholder="Confirmar Senha"
												name="confirmPassword"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowConfirmPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showConfirmPassword ? (
																	<VisibilityOff />
																) : (
																	<Visibility />
																)}
															</IconButton>
														</InputAdornment>
													)
												}}
												FormHelperTextProps={{ classes: helperText }}
												label="Confrimar Senha"
												error={Boolean(errorConfirmPassword)}
												helperText={errorConfirmPassword || ''}
											/>
										</Grid>

										<Button
											type="submit"
											fullWidth
											variant="contained"
											sx={{
												mt: 3,
												backgroundColor: '#021128',
												height: '5vh'
											}}
										>
											Entrar
										</Button>
									</Box>
								</Grid>
								<Copyright sx={{ mt: 4, mb: 4 }} />
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={6}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					{/* </Grid> */}
				</Grid>
			</Grid>
		</ThemeProvider>
		// </Paper>
	)
}

export default Register

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Avatar, Button, Grid, Paper, TextField } from '@mui/material'
import { ClientInformation } from '../../../types/clients'
import LoadingClientInfo from './loading-client-info'
import { statusToString } from '../../../utils/status-to-string'

interface ClientInfoProps {
	clientInfo: ClientInformation | null
	handleClose: () => void
}

function ClientInfo({ clientInfo, handleClose }: ClientInfoProps) {
	if (!clientInfo) {
		return <LoadingClientInfo />
	}
	return (
		<Grid>
			<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
				<Button onClick={handleClose} sx={{ color: '#000', width: 10 }}>
					<ArrowBackIcon />
				</Button>
				<Grid
					container
					spacing={4}
					sx={{
						display: 'flex',
						justifyContent: 'space-around'
					}}
				>
					<Grid
						item
						xs={4}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Avatar
							sx={{
								height: 120,
								width: 120
							}}
						/>
					</Grid>
					<Grid container spacing={3} item xs={8}>
						<Grid item xs={12}>
							<TextField
								label="Nome"
								sx={{
									width: '100%'
								}}
								defaultValue={clientInfo.name}
								InputProps={{
									readOnly: true
								}}
							/>
						</Grid>
						<Grid
							item
							container
							spacing={3}
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'space-around'
							}}
						>
							<Grid item xs={6}>
								<TextField
									label="Telefone"
									sx={{
										width: '100%'
									}}
									defaultValue={clientInfo.cellphoneNumber}
									InputProps={{
										readOnly: true
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Status"
									sx={{
										width: '100%'
									}}
									defaultValue={statusToString(clientInfo.status)}
									InputProps={{
										readOnly: true
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Cadastro"
							sx={{
								width: '100%'
							}}
							defaultValue={clientInfo.createdAt}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Aniversário"
							sx={{
								width: '100%'
							}}
							defaultValue={clientInfo.birthDate}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Valor total de compras"
							sx={{
								width: '100%'
							}}
							defaultValue={clientInfo.sumOfPurchases}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Premio à retirar"
							sx={{
								width: '100%'
							}}
							defaultValue={clientInfo.pendentPrize}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Ultima compra"
							sx={{
								width: '100%'
							}}
							defaultValue={clientInfo.lastPurchase}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Email"
							sx={{
								width: '100%'
							}}
							defaultValue={clientInfo.email}
							InputProps={{
								readOnly: true
							}}
						/>
					</Grid>
				</Grid>
				<Grid
					sx={{
						display: 'flex',
						justifyContent: 'space-around'
					}}
				/>
			</Paper>
		</Grid>
	)
}

export default ClientInfo

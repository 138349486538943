import EditIcon from '@mui/icons-material/Edit'
import {
	Button,
	CircularProgress,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead
} from '@mui/material'
import { TableRow } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { useCallback, useState } from 'react'
import { Title } from '../../../components'
import { SolicitationRoulette } from '../../../types/solicitation'
import {
	approveSolicitationAction,
	editSolicitationValueAction,
	removeSolicitationAction
} from '../../../actions/roulette'
import ConfirmationModal from '../../../components/confirmation-modal'
import { statusToTag } from '../../../components/status-to-tag'
import ModalChangeValue from './modal-change-value'

interface SolicitationGridProps {
	rows: SolicitationRoulette[] | null
	setRows: (rows: SolicitationRoulette[] | null) => void
}

function SolicitationsGrid({ rows, setRows }: SolicitationGridProps) {
	const [openModal, setOpenModal] = useState(false)
	const [rowEdit, setRowEdit] = useState<SolicitationRoulette | null>(null)
	const [deleteId, setDeleteId] = useState<
		{ id: string; index: number } | undefined
	>()

	const removeRow = (index: number) => {
		if (rows === null) {
			return
		}
		const newRows = rows.filter((_, i) => i !== index)
		setRows(newRows)
	}

	const handleApprobation = async (id: string, index: number) => {
		await approveSolicitationAction(id)
		toast.success('Solicitação aprovada com sucesso')
		removeRow(index)
	}

	const handleCancelModal = useCallback(() => {
		if (deleteId) {
			setDeleteId(undefined)
		}
		setOpenModal(false)
	}, [setDeleteId, setOpenModal])

	const handleOpenModal = useCallback(
		(id: string, index: number) => {
			setDeleteId({ id, index })
			setOpenModal(true)
		},
		[setDeleteId, setOpenModal]
	)

	const handleRejection = async (id: string, index: number) => {
		await removeSolicitationAction(id)
		toast.success('Solicitação rejeitada com sucesso')
		removeRow(index)
		setOpenModal(false)
		setDeleteId(undefined)
	}

	const handleCloseModalEdit = useCallback(() => {
		setRowEdit(null)
	}, [setRowEdit])

	const handleSaveEdit = useCallback(
		async (row: SolicitationRoulette) => {
			const { value } = await editSolicitationValueAction(
				row.id,
				Number(row.value)
			)
			row.value = value
			toast.success('Valor da solicitação alterado com sucesso')
			handleCloseModalEdit()
		},
		[handleCloseModalEdit]
	)

	const handleConfirmationModal = useCallback(async () => {
		if (deleteId) {
			await handleRejection(deleteId.id, deleteId.index)
		}
		handleCancelModal()
	}, [deleteId, handleRejection, handleCancelModal])

	if (rows === null) {
		return (
			<Grid
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%'
				}}
			>
				<CircularProgress
					sx={{
						color: '#94fb26'
					}}
				/>
			</Grid>
		)
	}
	return (
		<>
			<ConfirmationModal
				handleCancel={handleCancelModal}
				handleClose={handleCancelModal}
				handleConfirm={handleConfirmationModal}
				message="Deseja realmente rejeitar a solicitação?"
				title="Rejeitar Solicitação"
				open={openModal}
			/>
			<ModalChangeValue
				row={rowEdit}
				handleClose={handleCloseModalEdit}
				handleSaveEdit={handleSaveEdit}
			/>
			<Title>Aprovação de Clientes</Title>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Nome</TableCell>
						<TableCell>Data</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Telefone</TableCell>
						<TableCell align="right">Valor da Compra</TableCell>
						<TableCell align="center">Ações</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, index) => (
						<TableRow key={row.id}>
							<TableCell>{row.clientName}</TableCell>
							<TableCell>{row.date}</TableCell>
							<TableCell>{statusToTag[row.status]}</TableCell>
							<TableCell>{row.cellphone}</TableCell>
							<TableCell align="right">{row.value}</TableCell>
							<TableCell
								align="center"
								sx={{
									display: 'flex',
									justifyContent: 'space-evenly'
								}}
							>
								<Button
									variant="contained"
									color="success"
									onClick={() => {
										handleApprobation(row.id, index)
									}}
								>
									Aprovar
								</Button>
								<Button
									variant="contained"
									color="error"
									onClick={() => {
										handleOpenModal(row.id, index)
									}}
								>
									Reprovar
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										setRowEdit(row)
									}}
								>
									<EditIcon />
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</>
	)
}

export default SolicitationsGrid

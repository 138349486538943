import { saveAs } from 'file-saver'

export const saveFileFromRequest = (
	data: Buffer,
	headers?: Record<string, string>
) => {
	if (!headers || !headers['content-type']) return
	const binaryData = []
	binaryData.push(data)
	const blob = new Blob(binaryData, { type: headers['content-type'] })
	saveAs(blob, headers['x-suggested-filename'] || 'file')
}

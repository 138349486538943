import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import RoutesRoot from './routes'
import { AuthProvider } from './hooks'
import 'react-toastify/dist/ReactToastify.css'
import { SocketProvider } from './context/socket'

function App() {
	return (
		<BrowserRouter>
			<AuthProvider>
				<SocketProvider>
					{/* <Routes> */}
					<RoutesRoot />
					{/* </Routes> */}
				</SocketProvider>
			</AuthProvider>
			<ToastContainer autoClose={3000} />
		</BrowserRouter>
	)
}

export default App

import React, { createContext, useEffect, useMemo } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useAuth } from '../hooks'
import SOCKET from '../config/socket'

type SocketContextType = {
	connectionStatus?: string
	lastMessage: MessageEvent<any> | null
	message: MessageEvent<any> | null
	messageHistory: MessageEvent<any>[]
	readyState?: ReadyState
	handleSetMessage: (message: MessageEvent<any>) => void
}

const SocketContext = createContext<SocketContextType>({
	connectionStatus: undefined,
	lastMessage: null,
	message: null,
	messageHistory: [],
	readyState: undefined,
	handleSetMessage: () => {}
} as SocketContextType)

type PropsSocketProvider = {
	children: React.ReactNode
}

function SocketProvider({ children }: PropsSocketProvider) {
	const { user } = useAuth()
	const [messageHistory, setMessageHistory] = React.useState<
		MessageEvent<any>[]
	>([])
	const [message, setMessage] = React.useState<MessageEvent<any> | null>(null)
	const { lastMessage, readyState, sendMessage } = useWebSocket(
		SOCKET.baseURL,
		{
			protocols: user?.token,
			reconnectAttempts: 10,
			reconnectInterval: 3000,
			shouldReconnect: () => true,
			retryOnError: true,
			heartbeat: {
				message: 'ping',
				returnMessage: 'pong',
				interval: 10000,
				timeout: 30000
			}
		},
		Boolean(user)
	)

	const handleSetMessage = (message: MessageEvent<any>) => {
		setMessage(message)
	}

	useEffect(() => {
		if (lastMessage !== null) {
			setMessageHistory(prev => prev.concat(lastMessage))
			setMessage(lastMessage)
		}
	}, [lastMessage, setMessage, setMessageHistory])

	const connectionStatus = {
		[ReadyState.CONNECTING]: 'Connecting',
		[ReadyState.OPEN]: 'Open',
		[ReadyState.CLOSING]: 'Closing',
		[ReadyState.CLOSED]: 'Closed',
		[ReadyState.UNINSTANTIATED]: 'Uninstantiated'
	}[readyState]

	const contextValue = useMemo(
		() => ({
			connectionStatus,
			lastMessage,
			message,
			messageHistory,
			readyState,
			handleSetMessage
		}),
		[
			connectionStatus,
			lastMessage,
			message,
			messageHistory,
			readyState,
			handleSetMessage
		]
	)
	return (
		<SocketContext.Provider value={contextValue}>
			{children}
		</SocketContext.Provider>
	)
}

export { SocketProvider, SocketContext }

import { useTheme } from '@mui/material/styles'
import { BarChart, axisClasses } from '@mui/x-charts'
import { ChartsTextStyle } from '@mui/x-charts/ChartsText'
import Title from '../../../components/title'
import Loading from '../../../components/loading'

interface ChartProps {
	title: string
	keyX?: string
	keyY?: string
	dataKeySeries?: string
	labelX?: string
	labelY?: string
	data: any[] | null
}

export default function Chart({
	data,
	keyX,
	keyY,
	title,
	dataKeySeries,
	labelX,
	labelY
}: ChartProps) {
	const theme = useTheme()
	if (!data) {
		return (
			<>
				<Title>{title}</Title>
				<Loading />
			</>
		)
	}

	return (
		<>
			<Title>{title}</Title>
			<div
				style={{
					width: '100%',
					flexGrow: 1,
					overflow: 'hidden',
					height: '36vh'
				}}
			>
				<BarChart
					dataset={data}
					margin={{
						top: 16,
						right: 20,
						left: 70
					}}
					xAxis={[
						{
							label: labelX,
							scaleType: 'band',
							dataKey: keyX,
							tickLabelStyle: theme.typography.body2 as ChartsTextStyle
						}
					]}
					yAxis={[
						{
							label: labelY,
							dataKey: keyY,
							labelStyle: {
								...(theme.typography.body1 as ChartsTextStyle),
								fill: theme.palette.text.primary
							},
							tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
							tickNumber: 3
						}
					]}
					series={[
						{
							dataKey: dataKeySeries,
							color: theme.palette.primary.light
						}
					]}
					sx={{
						[`.${axisClasses.root} line`]: {
							stroke: theme.palette.text.secondary
						},
						[`.${axisClasses.root} text`]: {
							fill: theme.palette.text.secondary
						},
						[`& .${axisClasses.left} .${axisClasses.label}`]: {
							transform: 'translateX(-25px)'
						}
					}}
				/>
			</div>
		</>
	)
}

import { createTheme } from '@mui/material/styles'
import { ThemeProvider, makeStyles } from '@mui/styles'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Copyright } from '../../components'

const defaultTheme = createTheme()

function ChooseScreen() {
	const navigate = useNavigate()

	const handleClickRoulette = useCallback(() => {
		localStorage.setItem('defaultPage', '/dashboard')
		navigate('/dashboard')
	}, [])

	const handleClickPanel = useCallback(() => {
		localStorage.setItem('defaultPage', '/panel/approbation')
		navigate('/panel/approbation')
	}, [])

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid
				container
				sx={{
					height: '100vh',
					width: '100vw',
					backgroundImage: 'url(/bg_login.svg)',
					backgroundColor: 'white',
					backgroundPosition: 'left',
					backgroundSize: 'cover'
				}}
			>
				<Grid
					item
					xs={12}
					sx={{
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Grid
						item
						xs={12}
						sx={{
							height: '15vh'
						}}
					/>
					<Grid
						container
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Grid
							item
							xs={6}
							sx={{
								backdropFilter: 'blur(10px)',
								borderRadius: 10,
								backgroundColor: 'rgba(14, 112, 158, 0.5)',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<Grid xs={6} sx={{ height: '50vh' }}>
								<Grid container spacing={2}>
									<Grid
										item
										xs={12}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										<img
											srcSet="/logo.svg"
											src="/logo.svg"
											alt="Logo"
											loading="lazy"
											style={{
												height: '7vh',
												marginTop: 20,
												marginBottom: 40
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Typography
											variant="h5"
											align="left"
											sx={{ fontWeight: 'bold' }}
										>
											Acessar
										</Typography>
									</Grid>
									<Grid item xs={12} sx={{ paddingBottom: 4 }}>
										<Button
											sx={{
												backgroundColor: '#000',
												color: '#94fb26',
												fontWeight: 'bold',
												fontSize: '1.5rem',
												width: '100%',
												height: '6vh'
											}}
											onClick={handleClickPanel}
										>
											Painel
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Button
											sx={{
												backgroundColor: '#000',
												color: '#94fb26',
												fontWeight: 'bold',
												fontSize: '1.5rem',
												width: '100%',
												height: '6vh'
											}}
											onClick={handleClickRoulette}
										>
											Roleta
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ThemeProvider>
		// </Paper>
	)
}

export default ChooseScreen

import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import PeopleIcon from '@mui/icons-material/People'
import SettingsIcon from '@mui/icons-material/Settings'
import { Link } from '@mui/material'
import RouletteIcon from '../../components/icons/roulette'
import ApprobationIcon from '../../components/icons/approbation'
import FinancialIcon from '../../components/icons/financial'

const items = [
	<ListItemButton LinkComponent={Link} href="/dashboard">
		<ListItemIcon>
			<RouletteIcon />
		</ListItemIcon>
		<ListItemText primary="Roleta" />
	</ListItemButton>,
	<ListItemButton LinkComponent={Link} href="/panel/approbation">
		<ListItemIcon>
			<ApprobationIcon />
		</ListItemIcon>
		<ListItemText primary="Aprovação" />
	</ListItemButton>,
	<ListItemButton LinkComponent={Link} href="/panel/metrics">
		<ListItemIcon>
			<DashboardIcon />
		</ListItemIcon>
		<ListItemText primary="Dashboard" />
	</ListItemButton>,
	<ListItemButton LinkComponent={Link} href="/panel/clients">
		<ListItemIcon>
			<PeopleIcon />
		</ListItemIcon>
		<ListItemText primary="Clientes" />
	</ListItemButton>,
	<ListItemButton LinkComponent={Link} href="/panel/settings">
		<ListItemIcon>
			<SettingsIcon />
		</ListItemIcon>
		<ListItemText primary="Configurações" />
	</ListItemButton>,
	<ListItemButton LinkComponent={Link} href="/panel/plans">
		<ListItemIcon>
			<FinancialIcon />
		</ListItemIcon>
		<ListItemText primary="Assinatura" />
	</ListItemButton>
]

export const mainListItems = (selectedIndex: number) => {
	const attributeStyle = {
		backgroundColor: 'rgba(0, 0, 0, 0.05)'
	}
	return (
		<>
			{items.map((item, index) => ({
				...item,
				key: index,
				props: {
					...item.props,
					sx: selectedIndex === index ? attributeStyle : undefined
				}
			}))}
		</>
	)
}

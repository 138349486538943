export const formatPhone = (phone: string) => {
	if (phone.length === 11) {
		const phoneRegex = /(\d{2})(\d{5})(\d{4})/
		return phone.replace(phoneRegex, '($1) $2-$3')
	}
	if (phone.length === 13) {
		const phoneRegex = /(\d{2})(\d{2})(\d{5})(\d{4})/
		return phone.replace(phoneRegex, '+$1 ($2) $3-$4')
	}
	if (phone.length === 10) {
		const phoneRegex = /(\d{2})(\d{4})(\d{4})/
		return phone.replace(phoneRegex, '($1) $2-$3')
	}
	if (phone.length === 9) {
		const phoneRegex = /(\d{5})(\d{4})/
		return phone.replace(phoneRegex, '$1-$2')
	}
	return phone
}

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Pagination } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import Title from '../../../components/title'
import { PendingResults } from '../../../types/pending-results'
import Loading from '../../../components/loading'

interface PedingPrizesGridProps {
	rows: PendingResults[] | null
}

const PAGE_LENGTH = 7

export default function PendingPrizesGrid({ rows }: PedingPrizesGridProps) {
	const [renderRows, setRenderRows] = useState<PendingResults[]>([])
	const [length, setLength] = useState(0)
	const [pageIndex, setPageIndex] = useState(0)

	const pageRows = useCallback(() => {
		if (!rows) {
			return
		}
		setLength(rows.length)
		const newRows = rows.slice(
			pageIndex * PAGE_LENGTH,
			pageIndex * PAGE_LENGTH + PAGE_LENGTH
		)
		setRenderRows(newRows)
	}, [rows, pageIndex])

	useEffect(() => {
		const pages = Math.ceil(length / PAGE_LENGTH)
		if (pageIndex >= pages) {
			setPageIndex(pages)
		}
	}, [length])

	useEffect(() => {
		pageRows()
	}, [rows, pageIndex])

	if (!rows) {
		return (
			<>
				<Title>Prêmios Pendentes</Title>
				<Loading />
			</>
		)
	}
	return (
		<>
			<Title>Prêmios Pendentes</Title>
			<Table stickyHeader size="small">
				<TableHead>
					<TableRow>
						<TableCell sx={{ fontWeight: 'bold' }}>Cliente</TableCell>
						<TableCell sx={{ fontWeight: 'bold' }}>Telefone</TableCell>
						<TableCell sx={{ fontWeight: 'bold' }}>Prêmio</TableCell>
						<TableCell sx={{ fontWeight: 'bold' }} align="right">
							Valor de Custo
						</TableCell>
						<TableCell sx={{ fontWeight: 'bold' }} align="right">
							Valor Consumido
						</TableCell>
						<TableCell sx={{ fontWeight: 'bold' }} align="right">
							Aprovação
						</TableCell>
						<TableCell sx={{ fontWeight: 'bold' }} align="right">
							Validade
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{renderRows.map(row => (
						<TableRow key={row.id}>
							<TableCell>{row.name}</TableCell>
							<TableCell>{row.cellphoneNumber}</TableCell>
							<TableCell>{row.prize}</TableCell>
							<TableCell align="right">{row.valueCost}</TableCell>
							<TableCell align="right">{row.valueConsumed}</TableCell>
							<TableCell align="right">{row.createAt}</TableCell>
							<TableCell align="right">{row.expirationDate}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Pagination
				count={Math.ceil(length / PAGE_LENGTH)}
				onChange={(_, value) => {
					setPageIndex(value - 1)
				}}
			/>
		</>
	)
}

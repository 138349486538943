import { RouletteService } from '../services/roulette'
import { PendingResults } from '../types/pending-results'
import { SolicitationRoulette } from '../types/solicitation'
import { Spin } from '../types/spin'

export const getHistoryRoulette = async () => {
	const service = new RouletteService()
	const response = await service.getHistoryRoulette()
	return response.data as Spin[]
}

export const getSolicitationRouletteAction = async () => {
	const service = new RouletteService()
	const response = await service.getSolicitationRoulette()
	return response.data as SolicitationRoulette[]
}

export const approveSolicitationAction = async (id: string) => {
	const service = new RouletteService()
	await service.approveSolicitation(id)
}

export const removeSolicitationAction = async (id: string) => {
	const service = new RouletteService()
	await service.removeSolicitation(id)
}

export const editSolicitationValueAction = async (
	id: string,
	value: number
) => {
	const service = new RouletteService()
	const response = await service.editSolicitationValue(id, value)
	return response.data as { value: string }
}

export const getPendingsSolicitationAction = async () => {
	const service = new RouletteService()
	const response = await service.getPendingsSolicitation()
	return response.data as PendingResults[]
}

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'

interface ConfirmationModalProps {
	open: boolean
	handleClose: () => void
	handleCancel: () => void
	handleConfirm: () => void
	title: string
	message: string
}

function ConfirmationModal({
	handleCancel,
	handleClose,
	handleConfirm,
	open,
	title,
	message
}: ConfirmationModalProps) {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel}>Cancelar</Button>
				<Button onClick={handleConfirm} autoFocus>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmationModal

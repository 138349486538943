import Typography from '@mui/material/Typography'
import { Paper } from '@mui/material'
import Title from '../../../components/title'
import Loading from '../../../components/loading'

export interface CardDashboardProps {
	title: string
	subtitle: string
	value: string | null
}

export default function CardDashboard({
	subtitle,
	title,
	value
}: CardDashboardProps) {
	return (
		<Paper
			sx={{
				p: 2,
				display: 'flex',
				flexDirection: 'column',
				height: '100%'
			}}
		>
			<Title>{title}</Title>
			<Typography color="text.secondary" sx={{ flex: 1, fontSize: 18 }}>
				{subtitle}
			</Typography>
			<Typography component="p" variant="h4">
				{!value ? <Loading sxProgress={{ color: '#72bc16' }} /> : value}
			</Typography>
		</Paper>
	)
}

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { toast } from 'react-toastify'
import { RouletteOptions } from '../../../types/roulette'
import GridOptionsRoulette from '../../../components/config/grid-options'

type ModalConfigProps = {
	isOpen: boolean
	options: RouletteOptions[]
	onClose: () => void
	onSaved: (items: Partial<RouletteOptions>[]) => void
}

const defaultColors = ['#3E3E3E', '#DF3428']

function ModalConfig({ isOpen, options, onClose, onSaved }: ModalConfigProps) {
	const [items, setItems] = useState<Partial<RouletteOptions>[]>([
		{
			name: '',
			color: defaultColors[0]
		}
	])
	const [sumProbability, setSumProbability] = useState(0)

	useEffect(() => {
		if (options.length === 0) return
		setItems(options)
	}, [options])

	useEffect(() => {
		const sum = items.reduce((acc, item) => acc + (item.probability || 0), 0)
		setSumProbability(sum)
	}, [items])

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				component: 'form',
				onSubmit: (e: any) => {
					e.preventDefault()
					if (sumProbability !== 100) {
						toast.error('A soma das probabilidades deve ser 100')
						return
					}
					onSaved(items)
					onClose()
				}
			}}
			fullWidth
			maxWidth="lg"
			sx={{
				minWidth: '100%!important'
			}}
			key="modal-config"
		>
			<DialogTitle>Configurações da Roleta</DialogTitle>
			<DialogContent
				sx={{
					minHeight: '60vh',
					maxHeight: '60vh'
				}}
			>
				<DialogContentText>
					Escolha as opções que irão aparecer na roleta
				</DialogContentText>
				<GridOptionsRoulette
					items={items}
					setItems={setItems}
					sumProbability={sumProbability}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined">
					Cancelar
				</Button>
				<Button type="submit" variant="contained">
					<SaveIcon sx={{ paddingRight: 2 }} />
					Salvar
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ModalConfig

import { Grid, Paper, ToggleButton } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import ClientsGrid from './clients-grid'
import { Client, ClientInformation } from '../../../types/clients'
import {
	getClientInfoAction,
	getClientsAction
} from '../../../actions/clients.actions'
import ClientInfo from './client-info'
import ClientFilterStatus from './client-filter-status'
import { Sort, handleSortCompare } from '../../../enums/sort-clients'

function Clients() {
	const [rows, setRows] = useState<Client[] | null>(null)
	const [filterStatusRows, setFilterStatusRows] = useState<Client[] | null>(
		null
	)
	const [isBirthday, setIsBirthday] = useState(false)
	const [isAbsent, setIsAbsent] = useState(false)
	const [openClient, setOpenClient] = useState(false)
	const [selectedIndexFilter, setSelectedIndexFilter] = useState(0)

	const [clientInfo, setClientInfo] = useState<ClientInformation | null>(null)

	useEffect(() => {
		setFilterStatusRows(rows)
	}, [rows])

	const getClients = useCallback(async () => {
		const clients = await getClientsAction()
		setRows(clients)
	}, [setRows])

	const getClientInfo = useCallback(
		async (id: number) => {
			const client = await getClientInfoAction(id)
			setClientInfo(client)
		},
		[setClientInfo]
	)

	const handleClose = useCallback(() => {
		setOpenClient(false)
	}, [setOpenClient])

	const handleDoubleClickLine = useCallback(
		(client: Client) => {
			if (client.id === clientInfo?.id) {
				handleClose()
				setClientInfo(null)
				return
			}
			setClientInfo(null)
			getClientInfo(client.id)
			setOpenClient(true)
		},
		[clientInfo, setClientInfo, getClientInfo, setOpenClient]
	)

	const handleSort = (sort: Sort) => {
		if (!filterStatusRows) return
		filterStatusRows.sort((a, b) => handleSortCompare[sort](a, b))
		setFilterStatusRows([...filterStatusRows])
	}

	useEffect(() => {
		getClients()
	}, [])
	return (
		<Grid container spacing={3}>
			<Grid item xs={openClient ? 6 : 12}>
				<ClientFilterStatus
					selectedIndex={selectedIndexFilter}
					rows={rows}
					setFilterRows={setFilterStatusRows}
					setSelectedIndex={setSelectedIndexFilter}
					setAbsent={setIsAbsent}
					setBirthday={setIsBirthday}
				/>
				<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
					<ClientsGrid
						rows={filterStatusRows}
						handleSort={handleSort}
						isAbsent={isAbsent}
						isBirthday={isBirthday}
						handleDoubleClickLine={handleDoubleClickLine}
					/>
				</Paper>
			</Grid>
			{openClient && (
				<Grid item xs={6}>
					<ClientInfo clientInfo={clientInfo} handleClose={handleClose} />
				</Grid>
			)}
		</Grid>
	)
}
export default Clients

import { HTTP } from '../providers'

export class ApplicationService extends HTTP {
	async uploadLogo(logo: File) {
		const login = await this.postForm({
			endpoint: '/application/logo',
			data: { logo }
		})
		return login
	}

	async existsApplicationCode(code: string) {
		const login = await this.get({
			endpoint: `/application/code/${code}`
		})
		return login
	}

	async getApplicationInfo() {
		const login = await this.get({
			endpoint: '/application/info'
		})
		return login
	}

	async saveInfo(application: {
		rouletteMessage: string
		birthdayMessage: string
		absentClientsMessage: string
		expireDays: number
	}) {
		const login = await this.put({
			endpoint: '/application/info',
			data: application
		})
		return login
	}

	async downloadQr() {
		const login = await this.get({
			endpoint: '/application/download-qrcode',
			responseType: 'blob'
		})
		return login
	}
}

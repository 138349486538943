import { Grid, Typography } from '@mui/material'
import CardPlan from './card-plan'

function Plan() {
	return (
		<Grid container>
			<Typography
				variant="h3"
				sx={{
					color: 'rgba(0, 0, 0, 0.67)'
				}}
			>
				Escolha o melhor plano para você
			</Typography>
			<Grid container spacing={5} padding={5}>
				<Grid item xs={3}>
					<CardPlan
						title="BÁSICO"
						price="R$89,90"
						features={[
							{ title: 'Roleta de Prêmios', status: true },
							{ title: 'Métricas', status: true },
							{ title: 'Perguntas Customizadas', status: false },
							{ title: 'Número Próprio', status: false }
						]}
						color="#af2bbf"
						image="/icons/balao.svg"
						imageAlt="balão"
						handleClick={() => {}}
					/>
				</Grid>
				<Grid item xs={3}>
					<CardPlan
						title="AVANÇADO"
						price="R$147,70"
						features={[
							{ title: 'Roleta de Prêmios', status: true },
							{ title: 'Métricas', status: true },
							{ title: 'Perguntas Customizadas', status: true },
							{ title: 'Número Próprio', status: true }
						]}
						color="#574ae2"
						image="/icons/foguete.svg"
						imageAlt="foguete"
						handleClick={() => {}}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Plan

import { useCallback } from 'react'
import {
	Box,
	Button,
	Grid,
	InputAdornment,
	TextField,
	Tooltip
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import ColorPicker from 'material-ui-color-picker'
import DeleteIcon from '@mui/icons-material/Delete'
import { RouletteOptions } from '../../types/roulette'
import MoneyField from '../money-field'
import PercentField from '../percent-field'

type FieldsModalConfigProps = {
	item: Partial<RouletteOptions>
	index: number
	items: Partial<RouletteOptions>[]
	setItems: (items: Partial<RouletteOptions>[]) => void
}

function FieldsModalConfig({
	item,
	index,
	items,
	setItems
}: FieldsModalConfigProps) {
	const { name, color, probability, value } = item
	const handleChangeColor = useCallback(
		(color: any) => {
			if (!color) return
			const newItems = [...items]
			newItems[index].color = color
			setItems(newItems)
		},
		[items, setItems]
	)

	return (
		<Grid container spacing={2} key={`text-field${index}`}>
			<Grid item xs={5}>
				<TextField
					autoFocus
					required
					margin="dense"
					id={`${index}name`}
					value={name}
					sx={{
						width: '100%'
					}}
					onChange={e => {
						const newItems = [...items]
						newItems[index].name = e.target.value
						setItems(newItems)
					}}
					name="option"
					label="Nome"
					type="text"
				/>
			</Grid>
			<Grid item xs={2}>
				<PercentField
					required
					margin="dense"
					id={`${index}probability`}
					value={probability}
					sx={{
						width: '100%'
					}}
					onChange={e => {
						const value = Number(e.target.value)
						if (value < 0 || value > 100) return
						const newItems = [...items]
						newItems[index].probability = value
						setItems(newItems)
					}}
					name="probability"
					label="Vantagem"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip
									title="Defina a vantagem do item para a chance de sucesso de saída
"
								>
									<InfoIcon />
								</Tooltip>
							</InputAdornment>
						)
					}}
				/>
			</Grid>
			<Grid item xs={2}>
				<MoneyField
					required
					margin="dense"
					id={`${index}cost`}
					value={value}
					sx={{
						width: '100%'
					}}
					onChange={e => {
						const newItems = [...items]
						const value = Number(e.target.value)
						if (value < 0) return
						newItems[index].value = String(value)
						setItems(newItems)
					}}
					name="cost"
					label="Custo (R$)"
				/>
			</Grid>
			<Grid
				item
				xs={1}
				sx={{
					paddingTop: 1,
					paddingBottom: 1,
					paddingRight: '0!important',
					alignItems: 'center',
					marginBottom: '4px',
					display: 'flex'
				}}
			>
				<Button
					sx={{
						marginTop: '8px',
						backgroundColor: color,
						':hover': {
							cursor: 'default',
							backgroundColor: color
						},
						minWidth: '50px!important',
						maxWidth: '50%!important',
						height: '100%'
					}}
				>
					<ColorPicker
						id={`${index}color`}
						name="color"
						variant="standard"
						convert="hex"
						value={color}
						onChange={handleChangeColor}
					/>
				</Button>
			</Grid>
			<Grid item xs={2}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'right',
						alignItems: 'center',
						paddingLeft: '0!important',
						paddingRight: '0!important',
						height: '100%',
						width: '100%'
					}}
				>
					<DeleteIcon
						onClick={() => {
							const newItems = [...items]
							newItems.splice(index, 1)
							setItems(newItems)
						}}
						color="error"
					/>
				</Box>
			</Grid>
		</Grid>
	)
}
export default FieldsModalConfig

import axios, { AxiosInstance, ResponseType } from 'axios'
import { API } from '../config'

type Response = {
	data: any
	headers?: Record<string, string>
	status: number
	success: boolean
}

type GetParams = {
	endpoint: string
	params?: Record<string, any>
	responseType?: ResponseType
}

type PostParams = {
	endpoint: string
	data: Record<string, any>
}

type PutParams = {
	endpoint: string
	data: Record<string, any>
}

type DeleteParams = {
	endpoint: string
	data?: Record<string, any>
}

interface HTTPProvider {
	get(settings: GetParams): Promise<Response>
	post(settings: PostParams): Promise<Response>
	put(settings: PutParams): Promise<Response>
	delete(settings: DeleteParams): Promise<Response>
}

export interface RequestError {
	message: string
	status: number
	type: string
}

export class HTTP implements HTTPProvider {
	private axios: AxiosInstance

	constructor() {
		this.axios = axios.create(API)
		this.axios.interceptors.request.use(config => {
			config.headers.authorization = (() => {
				const token = localStorage.getItem('token')
				return token ? `Bearer ${token}` : ''
			})()
			config.headers['ngrok-skip-browser-warning'] = '69420'
			return config
		})

		this.axios.interceptors.response.use(
			response => response,
			err => {
				const error = err.response
				if (error?.status === 401) {
					console.log('unauthorized')
					localStorage.removeItem('token')
				}
				return Promise.reject(error)
			}
		)
	}

	private async handleResponse(response: Response): Promise<Response> {
		const { data, status, headers } = response
		return {
			data,
			status,
			headers,
			success: status >= 200 && status < 300
		}
	}

	async get(params: GetParams): Promise<Response> {
		const { endpoint, params: queryParams, responseType } = params
		const paramsData = Object.fromEntries(
			Object.entries(queryParams || {}).filter(([_, value]) => value)
		)
		return this.handleResponse(
			await this.axios.get(endpoint, { params: paramsData, responseType })
		)
	}

	async postForm(params: PostParams): Promise<Response> {
		const { endpoint, data } = params
		const formData = new FormData()
		Object.entries(data).forEach(([key, value]) => {
			formData.append(key, value)
		})
		return this.handleResponse(
			await this.axios.post(endpoint, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
		)
	}

	async post(params: PostParams): Promise<Response> {
		const { endpoint, data } = params
		return this.handleResponse(await this.axios.post(endpoint, data))
	}

	async put(params: PutParams): Promise<Response> {
		const { endpoint, data } = params
		return this.handleResponse(await this.axios.put(endpoint, data))
	}

	async delete(params: DeleteParams): Promise<Response> {
		const { endpoint, data } = params
		return this.handleResponse(await this.axios.delete(endpoint, { data }))
	}
}

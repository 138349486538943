import LogoutIcon from '@mui/icons-material/Logout'
import UploadIcon from '@mui/icons-material/Upload'
import {
	Box,
	Button,
	Grid,
	SpeedDial,
	SpeedDialAction,
	SpeedDialIcon,
	Typography,
	styled
} from '@mui/material'
import { Wheel } from 'react-custom-roulette'
import {
	ChangeEvent,
	memo,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react'
import ReactConfetti from 'react-confetti'
import { useNavigate } from 'react-router'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { RouletteOptions } from '../../types/roulette'
import ModalConfig from './modal-config/modal'
import {
	getOptionsRouletteAction,
	saveOptionsRouletteAction
} from '../../actions/roulette-options'
import HistoryGrid from './history/history'
import { SocketContext } from '../../context/socket'
import { SocketModule } from '../../enums/socket'
import DialogAward from './dialog/award'
import { Spin } from '../../types/spin'
import { useAuth } from '../../hooks'
import { getHistoryRoulette } from '../../actions/roulette'
import ExportModal from './export-modal/modal'

type Award = {
	awardName: string
	clientName: string
	prizeNumber: number
}

type RouletteData = {
	option: string
}

type RouletteProps = {
	data: { option: string }[]
	mustStartSpinning: boolean
	prizeNumber: number
	onStopSpinning: () => void
	backgroundColors: string[]
}

const Roulette = memo((props: RouletteProps) => (
	<Wheel {...props} textColors={['#ffffff']} disableInitialAnimation />
))

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
})

function DashBoard() {
	const emptyAward = {
		awardName: '',
		clientName: '',
		prizeNumber: 0
	}

	const navigate = useNavigate()
	const { logout, uploadLogo, imageApplication, application } = useAuth()

	// ['#3e3e3e', '#df3428']
	const { message, connectionStatus } = useContext(SocketContext)
	const [optionsRoulette, setOptionsRoulette] = useState<RouletteOptions[]>([])
	const [options, setOptions] = useState<RouletteData[]>([])
	const [colors, setColors] = useState<string[]>([])

	const [showHistory, setShowHistory] = useState(false)
	const [showAward, setShowAward] = useState(false)
	const [award, setAward] = useState<Award>(emptyAward)
	const [initialConnection, setInitialConnection] = useState(false)
	const [errorSocket, setErrorSocket] = useState(false)
	const [loading, setLoading] = useState(false)
	const [awardQueue, setAwardQueue] = useState<Spin[]>([])

	const [mustSpin, setMustSpin] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [openModalExport, setOpenModalExport] = useState(false)
	const [history, setHistory] = useState<Spin[]>([])

	const handleMessageRouletteOptions = useCallback(
		(data: RouletteOptions[]) => {
			setOptionsRoulette(data)
		},
		[setOptionsRoulette]
	)

	const handleUploadFile = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			console.log(event)
			if (!event.target?.files || !event.target?.files.length) {
				return
			}
			const file = event.target.files[0]
			uploadLogo(file)
		},
		[uploadLogo]
	)

	const handleMessageSpin = useCallback(
		(data: Spin) => {
			if (mustSpin || showAward) {
				setAwardQueue([...awardQueue, data])
				setHistory([data, ...history])
				return
			}
			setAward({
				awardName: data.selectedOption.name,
				clientName: data.name,
				prizeNumber: data.selectedOptionIndex
			})
			setMustSpin(true)
			setHistory([data, ...history])
		},
		[history, mustSpin, showAward, award, setHistory, setAward, setMustSpin]
	)

	const clearAward = useCallback(() => {
		setAward(emptyAward)
	}, [setAward])

	const nextQueueSpin = useCallback(() => {
		if (awardQueue.length) {
			const [spin, ...rest] = awardQueue
			setAwardQueue(rest)
			setAward({
				awardName: spin.selectedOption.name,
				clientName: spin.name,
				prizeNumber: spin.selectedOptionIndex
			})
			setMustSpin(true)
		}
	}, [awardQueue, setAward, setMustSpin])

	const handleLogout = useCallback(() => {
		logout()
	}, [logout])

	useEffect(() => {
		if (!message) {
			return
		}
		const { data } = message
		if (!data) {
			return
		}
		if (data.includes(SocketModule.ROULETTE_OPTIONS)) {
			const value = JSON.parse(data)
			handleMessageRouletteOptions(value.data)
		}
		if (data.includes(SocketModule.SPIN)) {
			const value = JSON.parse(data)
			handleMessageSpin(value.data)
		}
	}, [message])

	useEffect(() => {
		setOptions(optionsRoulette.map(item => ({ option: item.name })))
		setColors(optionsRoulette.map(item => item.color))
		setLoading(false)
	}, [optionsRoulette])

	useEffect(() => {
		if (connectionStatus === 'Open') {
			setInitialConnection(true)
			setErrorSocket(false)
			return
		}
		if (connectionStatus === 'Connecting' && !initialConnection) {
			setErrorSocket(false)
			return
		}
		setErrorSocket(true)
	}, [connectionStatus])

	const fetchOptionsRoulette = useCallback(async () => {
		setLoading(true)
		const options = await getOptionsRouletteAction()
		setOptionsRoulette(options)
	}, [])

	const fetchHistoryRoulette = useCallback(async () => {
		const spins = await getHistoryRoulette()
		setHistory(spins)
	}, [setHistory])

	useEffect(() => {
		fetchOptionsRoulette()
		// fetchHistoryRoulette()
	}, [])

	const handleGoToPanel = useCallback(() => {
		navigate('/panel/metrics')
	}, [])

	const saveOptions = useCallback(async (items: Partial<RouletteOptions>[]) => {
		setLoading(true)
		await saveOptionsRouletteAction(items)
	}, [])

	const handleStopSpinning = useCallback(() => {
		setMustSpin(false)
		setShowAward(true)
	}, [setMustSpin, setShowAward])

	return (
		<Grid
			container
			sx={{
				backgroundImage:
					'linear-gradient(45deg, #030a14, #030a14, #030a14, #030a14, #111c2e, #111c2e, #111c2e, #1e1e1e, #1e1e1e)',
				height: '100vh'
			}}
		>
			<Grid
				container
				sx={{
					position: 'absolute'
				}}
			>
				{imageApplication ? (
					<img
						srcSet={imageApplication || ''}
						src={imageApplication || ''}
						alt="Logo"
						loading="lazy"
						style={{
							width: '10%',
							height: '10%',
							objectFit: 'cover'
						}}
					/>
				) : (
					<Button
						sx={{
							backgroundColor: '#ffffff',
							color: '#000000',
							borderRadius: 10,
							marginTop: 2,
							marginLeft: 2,
							width: '15%',
							height: '7vh'
						}}
						component="label"
						role={undefined}
						variant="contained"
						tabIndex={-1}
						startIcon={<UploadIcon />}
					>
						Upload Logo
						<VisuallyHiddenInput
							onChange={handleUploadFile}
							accept=".jpg,.jpeg,.webp,.svg,.png"
							type="file"
						/>
					</Button>
				)}
			</Grid>
			<Grid item xs={showHistory ? 8 : 12}>
				<Grid
					container
					spacing={2}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Grid item xs={2} />
					<Grid
						item
						xs={8}
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: 2
						}}
					>
						<Typography
							variant="h2"
							sx={{
								textAlign: 'center',
								color: '#ffffff'
							}}
						>
							{application?.rouletteMessage}
						</Typography>
					</Grid>
					<Grid
						item
						xs={2}
						sx={{
							paddingRight: 2,
							display: 'flex',
							justifyContent: 'end',
							alignItems: 'end'
						}}
					/>
					<SpeedDial
						ariaLabel="Opções"
						sx={{ position: 'absolute', bottom: 16, right: 16 }}
						icon={<SpeedDialIcon />}
					>
						<SpeedDialAction
							key="Painel"
							icon={<DashboardIcon />}
							tooltipTitle="Painel"
							onClick={handleGoToPanel}
						/>
						{/* <SpeedDialAction
							key="Configurações"
							icon={<SettingsApplications />}
							tooltipTitle="Configurações"
							onClick={() => {
								setOpenModal(true)
							}}
						/> */}
						{/* <SpeedDialAction
							key="Exportar Clientes"
							icon={<GetApp />}
							tooltipTitle="Exportar Clientes"
							onClick={() => {
								setOpenModalExport(true)
							}}
						/> */}
						{/* <SpeedDialAction
							key="Histórico"
							icon={<History />}
							tooltipTitle="Histórico"
							onClick={() => {
								setShowHistory(!showHistory)
							}}
						/> */}
						<SpeedDialAction
							key="Logout"
							icon={<LogoutIcon />}
							tooltipTitle="Logout"
							onClick={handleLogout}
						/>
					</SpeedDial>
				</Grid>
				{errorSocket && (
					<Typography
						variant="h6"
						sx={{
							textAlign: 'center',
							color: 'red'
						}}
					>
						Erro na conexão com o servidor
					</Typography>
				)}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'right',
						p: 2
					}}
				>
					<ModalConfig
						isOpen={openModal}
						options={optionsRoulette}
						onClose={() => {
							setOpenModal(false)
						}}
						onSaved={saveOptions}
					/>
					<ExportModal
						open={openModalExport}
						onClose={() => setOpenModalExport(false)}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'right',
						p: 2
					}}
				/>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						p: 2
					}}
				>
					{loading ? (
						<Typography variant="h6">Carregando...</Typography>
					) : options.length ? (
						<Grid>
							<Grid container>
								<Roulette
									mustStartSpinning={mustSpin}
									prizeNumber={award.prizeNumber}
									data={options}
									backgroundColors={colors}
									onStopSpinning={handleStopSpinning}
								/>
							</Grid>
						</Grid>
					) : (
						<Typography variant="h6">Nenhuma opção cadastrada</Typography>
					)}
				</Box>
				<Grid
					container
					sx={{
						position: 'absolute',
						bottom: 0,
						width: '100%',
						display: 'flex'
					}}
				>
					<img
						srcSet="/logo_felidelizap_branca.svg"
						src="/logo_felidelizap_branca.svg"
						alt="Logo"
						loading="lazy"
						style={{
							height: '7vh',
							paddingLeft: 10
						}}
					/>
				</Grid>
			</Grid>
			{showHistory && (
				<Grid item xs={4}>
					<HistoryGrid history={history} />
				</Grid>
			)}
			{showAward && (
				<Grid>
					<ReactConfetti run={showAward} />
					<DialogAward
						handleClose={() => {
							setShowAward(false)
							clearAward()
							setTimeout(() => {
								nextQueueSpin()
							}, 500)
						}}
						awardName={award.awardName}
						open={showAward}
						clientName={award.clientName}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default DashBoard

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField
} from '@mui/material'
import { useAuth } from '../../hooks'

interface ModalUserProps {
	isOpen: boolean
	onClose: () => void
}

function ModalUser({ isOpen, onClose }: ModalUserProps) {
	const { imageApplication, application } = useAuth()

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
			sx={{
				minWidth: '100%!important'
			}}
			key="modal-config"
		>
			<DialogTitle>Seus Dados</DialogTitle>
			<DialogContent>
				<Grid container spacing={3} sx={{ marginTop: 1 }}>
					<Grid item xs={4}>
						{imageApplication ? (
							<img
								srcSet={imageApplication || ''}
								src={imageApplication || ''}
								alt="Logo"
								loading="lazy"
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover'
								}}
							/>
						) : (
							<AccountCircleIcon />
						)}
					</Grid>
					<Grid container spacing={3} item xs={8}>
						<Grid item xs={12}>
							<TextField
								label="E-mail"
								sx={{
									width: '100%'
								}}
								defaultValue={application?.email}
								InputProps={{
									readOnly: true
								}}
							/>
						</Grid>
						<Grid
							item
							container
							spacing={3}
							xs={12}
							sx={{
								display: 'flex',
								justifyContent: 'space-around'
							}}
						>
							<Grid item xs={6}>
								<TextField
									label="Código"
									sx={{
										width: '100%'
									}}
									defaultValue={application?.code}
									InputProps={{
										readOnly: true
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Nome"
									sx={{
										width: '100%'
									}}
									defaultValue={application?.name}
									InputProps={{
										readOnly: true
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalUser
